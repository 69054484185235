import moment from "moment";
import { StockIsoWeekItem } from "../../models/Stock";

class StockSliceLogic{
    transformIsoWeeks(isoWeeks: string[]): StockIsoWeekItem[]{
        return isoWeeks.map(x =>{
            const year = x.substring(0, 2);
          const w = x.substring(2, 4);
    
          const fullYear = parseInt(year) + 2000;
          const startMoment = moment()
            .isoWeekYear(fullYear)
            .isoWeeks(Number(w))
            .isoWeekday(1);
          const endMoment = moment(startMoment).add(6, "days");
    
          const startDateText = startMoment.format("Do MMMM YYYY");
          const endDateText = endMoment.format("Do MMMM YYYY");
    
          return {
            year: year,
            week: w,
            startDate: startDateText,
            endDate: endDateText,
            isoWeek: x
          };
        });
    }
}

export const stockSliceLogic = new StockSliceLogic();