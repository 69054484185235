import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../backend/SitePortalStaticApi";
import { DeliveryResults } from "../../models/Deliveries";
import { notification } from "../../notification";
import { ApplicationState } from "../reducers";

const initialSortOrder = '';

export const deliverySlice = createSlice({
    name: 'delivery',
    initialState: {
        inProgress: true,
        data: {
            items: [],
            totalItems: 0,
        } as DeliveryResults,
        pageSize: 10,
        pageIndex: 1,
        sortOrder: initialSortOrder,
        search: '',
        beforeDate: undefined as undefined | string, // moment().unix(),
        afterDate: undefined as undefined | string,// moment().add(-7, 'day').unix(),
        status: 'any',

    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setPageIndex(state, action: PayloadAction<number>) { state.pageIndex = action.payload },
        setSortOrder(state, action: PayloadAction<string>) { 
            if(action.payload){
                state.sortOrder = action.payload;
            }
            else {
                state.sortOrder = initialSortOrder;
            }
        },
        setData(state, action: PayloadAction<DeliveryResults>) { state.data = action.payload },
        setSearch(state, action: PayloadAction<string>) { state.search = action.payload; state.pageIndex = 1; },
        setProduct(state, action: PayloadAction<string>) { state.status = action.payload; state.pageIndex = 1; },
        setBeforeDate(state, action: PayloadAction<string | undefined>) { state.beforeDate = action.payload; state.pageIndex = 1; },
        setAfterDate(state, action: PayloadAction<string | undefined>) { state.afterDate = action.payload; state.pageIndex = 1; },
    }
});

export function fetchDeliveriesThunk() {
    return function (dispatch: Dispatch, getState: () => ApplicationState) {
        dispatch(deliverySlice.actions.startLoading());
        const state = getState().deliveries;

        return api.fetchDeliveries(
            state.pageIndex,
            state.pageSize,
            state.sortOrder,
            state.status,
            state.search,
            state.afterDate,
            state.beforeDate
            
        )
            .then(response => response.json())
            .then((results: DeliveryResults) => {
                dispatch(deliverySlice.actions.setData(results));
            })
            .catch(reason => notification.fetchDeliveriesError())
            .finally(() => dispatch(deliverySlice.actions.endLoading()));
    }
}