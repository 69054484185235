import { Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { DeliveryRecord } from '../../../../models/Deliveries';

interface Props {
    record: DeliveryRecord;
    text: string;
}

export default function (props: Props) {

    const record = props.record;

    const ordered = moment(props.text).format('Do MMMM YYYY');
    
    if (record.hasConfirmation) {
        const confirmed = moment(record.confirmedDate).format('Do MMMM YYYY');
        const title = (
            <table>
                <tbody>
                    <tr><td>Expected:</td><td style={{ paddingLeft: '0.5em' }}>{ordered}</td></tr>
                    <tr><td>Confirmed as:</td><td style={{ paddingLeft: '0.5em' }}>{confirmed}</td></tr>
                </tbody>
            </table>
        );
        return (
            <Tooltip title={title}>
                <span className='nowrap has-tooltip'>{confirmed}</span>
            </Tooltip>
        );
    }
    if (record.isRequested) {
        // TODO This belongs elsewhere
        let timeSlots = {
            'midnight': 'Midnight',
            'early-morning': 'Early Morning',
            'midday': 'Midday',
            'afternoon': 'Afternoon',
            'evening': 'Evening',
            'morning': 'Morning'
        } as any;

        const timeSlot = timeSlots[record.timeSlot];
        const title = (
            <table>
                <tbody>
                    <tr><td>Requested for:</td><td style={{ paddingLeft: '0.5em' }}>{ordered}</td></tr>
                    <tr><td>Time slot:</td><td style={{ paddingLeft: '0.5em' }}>{timeSlot}</td></tr>
                </tbody>
            </table>
        );
        return (
            <Tooltip title={title}>
                <span className='nowrap has-tooltip'>{ordered}</span>
            </Tooltip>
        );
    }

    return (<div className='nowrap'>{ordered}</div>);
}