import { DatePicker } from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { dateMoment } from '../../backend/DateMoment';

interface Props {
    value?: string;
    untilDate?: string;
    onChange(value?: string): void;
    format: string;
    placeholder: string;
    allowClear?: boolean;
}

export default function (props: Props) {

    const value = dateMoment(props.value).date;

    function isDisabledAfterDate(date: Moment | null){
        
        const untilDate = dateMoment(props.untilDate).date;

        if(date && value && untilDate){

            return date.valueOf() > untilDate.clone().add(1, 'days').valueOf();
        }

        return false;
    }

    function onChange(value: Moment | null){
        if(!value){
            props.onChange();
        }else {
            props.onChange(dateMoment(value).toDateString());
        }
    }

    return (<DatePicker format={props.format} disabledDate={isDisabledAfterDate}  placeholder={props.placeholder} value={value} onChange={onChange} allowClear={props.allowClear} />);
}