import { Checkbox, Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { api } from '../../../backend/SitePortalStaticApi';
import { User, UserPermissionData } from '../../../models/Users';
import { notification } from '../../../notification';
import { fetchSiteWithStockBalancesThunk } from '../../../store/slices/SiteWithStockBalanceSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import UserPermissionSiteTable from './UserPermissionSiteTable';

interface Props {
    record: User;
    refresh(): void;
    title: string;
    show: boolean;
    close(): void;
}

export default function (props: Props) {

    const dispatch = useDispatch();

    const [form] = useForm();

    const [inProgress, setInProgress] = useState(false);

    const reduxData = useSelectorShallow(s => s.sitesWithStockBalances);

    const noData = reduxData.data.length === 0;

    useEffect(() => {
        if (noData) {
            dispatch(fetchSiteWithStockBalancesThunk());
        }
        // eslint-disable-next-line
    }, []);

    async function submit(values: UserPermissionData) {
        try {
            setInProgress(true);
            values.id = props.record.id;

            await api.postUserPermissions(values);

            notification.updateUserPermissionsSuccess();

            form.resetFields();
            setInProgress(false);
            props.refresh();
            props.close();

        } catch (error) {
            notification.updateUserPermissionsError();
            setInProgress(false);
        }
    }

    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title={props.title} okText="Save" confirmLoading={inProgress} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={submit}>
            {noData && <p>Loading...</p>}
            <FormItem name="sites" initialValue={props.record.sites}>
                <Checkbox.Group disabled={noData}>
                    <UserPermissionSiteTable sites={reduxData.data.map(s => s.site)} />
                </Checkbox.Group>
            </FormItem>
        </Form>
    </Modal>);
}