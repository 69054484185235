import { Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { domainChangeService } from '../../../backend/DomainChangeService';
import { api } from '../../../backend/SitePortalStaticApi';
import { notification } from '../../../notification';
import { domainSlice } from '../../../store/slices/DomainSlice';
import { statusSlice } from '../../../store/slices/StatusSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';

interface Props {
    show: boolean;
    close(): void;
    domainId: string | null;

}

export default function (props: Props) {

    const [selectedDomainId, setSelectedDomainId] = useState(props.domainId);

    const [inProgress, setInProgress] = useState(false);


    const reduxState = useSelectorShallow(s => s.domains);

    const dispatch = useDispatch();

    async function submit() {
        try {
            setInProgress(true);
            await api.postDomainChange(selectedDomainId);
            dispatch(domainChangeService.setThunk(selectedDomainId));
            dispatch(statusSlice.actions.setDomainId(selectedDomainId));
            notification.updateUserDomainSuccess();
            props.close();

            setTimeout( () => window.location.reload(), 1900);

        } catch (error) {
            notification.updateUserDomainError();
        }
        setInProgress(false);

    }



    useEffect(() => {

        const loadData = async () => {
            try {
                dispatch(domainSlice.actions.start());
                const domains = await (await api.fetchDomains()).json();

                dispatch(domainSlice.actions.set(domains));

            } catch (error) {
                notification.fetchDomainsError();
                dispatch(domainSlice.actions.end());
                props.close();
            }
        }

        if (!reduxState.domainList) {
            loadData();
        }
        // eslint-disable-next-line
    }, [reduxState.domainList])

    return (<>
        <Modal open={props.show} closable destroyOnClose onCancel={props.close} title="Administrator settings" confirmLoading={inProgress} onOk={() => submit()}  >
            {reduxState.inProgress && "Loading..."}
            {!reduxState.inProgress && reduxState.domainList && <div>
                <p>Domain:</p>
                <Select
                    style={{ width: '100%' }}
                    defaultValue={props.domainId ?? undefined}
                    showSearch
                    filterOption={(input, option) => {

                        return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={(value) => {
                        setSelectedDomainId(value);
                    }}
                    placeholder="No Domain selected. Please type to search..."
                    options={reduxState.domainList.map(item => ({ label: item.name, value: item.id }))}>
                </Select>
            </div>}
        </Modal>
    </>);
}