import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { DeliveryRecord } from '../../../models/Deliveries';
import DeliveryConfirmationModalForm from './DeliveryConfirmationModalForm';

interface Props {
    record: DeliveryRecord;
    refresh(): void;
}

export default function (props: Props) {
    
    const [show, setShow] = useState(false);
    
    
    if (props.record.isActive && !props.record.isFuture && !props.record.hasConfirmation) {
        return (<>
            <Tooltip title='Confirm delivery' placement='topRight'>
                <Button shape='circle' type='primary' onClick={() => setShow(true)}>
                <CheckCircleOutlined />
                </Button>
            </Tooltip>
            {show && <DeliveryConfirmationModalForm record={props.record} show={show} close={() => setShow(false)} refresh={props.refresh}  />}
        </>);
    } else {
        return (
            <Button shape='circle' type='primary'  disabled>
                <CheckCircleOutlined />
            </Button>
        );
    }
}