import { Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch as useDispatch } from '../../../../store/hooks';
import { SiteWithStockBalance } from '../../../../models/SitesWithStockBalances';
import { fetchSiteWithStockBalancesThunk } from '../../../../store/slices/SiteWithStockBalanceSlice';
import { useSelectorShallow } from '../../../../store/utils/useSelectorShallow';
import StockBalance from './StockBalance';

interface Props {
    name: string;
    label: string;
    selectedSite?: string;
}

export default function (props: Props) {

    const dispatch = useDispatch();
    const reduxData = useSelectorShallow(s => s.sitesWithStockBalances);

    const [siteStockBalance, setStockBalance] = useState(null as SiteWithStockBalance | null);
    

    useEffect(() => {
        if((!reduxData.data || reduxData.data.length === 0 ) && !reduxData.hasError){
            dispatch(fetchSiteWithStockBalancesThunk());
        }
    });

    function onChange(value: string) {
        if(reduxData.data){
            const balance = reduxData.data.find(s => s.site.siteNumber === value);
            
            if(balance){
                setStockBalance(balance);

                return;
            }
        }

        setStockBalance(null);
    }


    return (<div>
        <FormItem label="Site" name={props.name} rules={[{ required: true, message: 'Please select a site' }]}>
                <Select onChange={onChange} style={{width: '100%'}}>
                    {reduxData.data && reduxData.data.map((v) => <Select.Option key={v.site.siteNumber} value={v.site.siteNumber}>{v.site.siteName} — {v.site.town}, {v.site.postCode}</Select.Option>)}
                </Select>
        </FormItem>
        {siteStockBalance && siteStockBalance.stockBalance && <StockBalance stockBalance={siteStockBalance.stockBalance} />}
    </div>);
}