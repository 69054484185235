import { Tooltip } from 'antd';
import React from 'react';
import { DeliveryRecord } from '../../../../models/Deliveries';

interface Props {
    text: string;
    record: DeliveryRecord;
}

export default function (props: Props) {

    const record = props.record;

    if (record.isRequested) {

        const referenceNumberTitle = (
            <div style={{paddingRight: '0.5em', paddingLeft: '0.5em'}}>
                Reference number: <strong>{ record.id }</strong>
            </div>
        );

        return (
            <Tooltip title={referenceNumberTitle}>
                <span className='nowrap has-tooltip'>REQUESTED</span>
            </Tooltip>
        );
    }
    
    return <>{props.text}</>;
}