import { notification as ant_notification, message } from 'antd';

const successDuration = 10; // seconds


export const notifyError = (message: any) => {
    ant_notification.error({
	    message: message,
	    description: 'A problem occurred, please try again later.',
	    duration: 10,
    });
};

const notifyWarning = (message: any, description?: any) => {
    if (!description) {
        // description = 'TODO Put some informative text here';
        description = '';
    }
    ant_notification.warning({
        message: message,
        description: description,
        duration: 0,
    });
};

const notifySuccess = (message: any, description?: any) => {
    if (!description) {
        // description = 'TODO Put some informative text here';
        description = '';
    }
    ant_notification.success({
        message: message,
        description: description,
        duration: successDuration,
    });
};

const newUnconfirmedDeliveries = (count?: any) => {
    notifyWarning('Unconfirmed Deliveries');
    //api.throttle(() => notifyWarning('Unconfirmed Deliveries'), 5 * 60 * 1000); // 5 mins
};

const logoutInProcess = () => {
    message.loading('Signing out...', 60);
};

const fetchSessionStateError = () => {
    notifyError('Unable to fetch Session State');
};

const fetchSitesError = () => {
    notifyError('Unable to fetch Sites');
};

const fetchSiteDetailsError = () => {
    notifyError('Unable to fetch Site Details!');
};


const updateSiteDetailsSuccess = () => {
    notifySuccess('Site Details updated successfully!');
};
const updateSiteDetailsError = () => {
    notifyError('Unable to update Site Details!');
};


const fetchDeliveriesError = () => {
    notifyError('Unable to fetch Deliveries!');
};


const sendDeliveryRequestSuccess = () => {
    notifySuccess('Delivery Request sent successfully!');
};
const sendDeliveryRequestError = () => {
    notifyError('Unable to send Delivery Request!');
};


const sendDeliveryQuerySuccess = () => {
    notifySuccess('Delivery Query sent successfully!');
};
const sendDeliveryQueryError = () => {
    notifyError('Unable to send Delivery Query!');
};


const sendDeliveryConfirmationSuccess = () => {
    notifySuccess('Delivery Confirmation sent successfully!');
};
const sendDeliveryConfirmationError = () => {
    notifyError('Unable to send Delivery Confirmation!');
};


const sendDeliveryChangeSuccess = () => {
    notifySuccess('Change Request sent successfully!');
};
const sendDeliveryChangeError = () => {
    notifyError('Unable to send Change Request!');
};


const fetchInvoicesError = () => {
    notifyError('Unable to fetch Invoices!');
};

const fetchStockError = () => {
    notifyError('Unable to fetch Stock Records!');
};


const sendStockQuerySuccess = () => {
    notifySuccess('Stock Query sent successfully!');
};
const sendStockQueryError = () => {
    notifyError('Unable to send Stock Query!');
};


const sendStockConfirmationSuccess = () => {
    notifySuccess('Stock Confirmation sent successfully!');
};
const sendStockConfirmationError = () => {
    notifyError('Unable to send Stock Confirmation!');
};


const fetchTransactionsError = () => {
    notifyError('Unable to fetch Transactions!');
};


const sendContactMessageSuccess = () => {
    notifySuccess('Message sent successfully!');
};
const sendContactMessageError = () => {
    notifyError('Unable to send Message!');
};



const fetchDomainsError = () => {
    notifyError('Unable to fetch Domains!');
};

const updateUserDomainError = () => {
    notifyError('Unable to change Domain!');
};

const updateUserDomainSuccess = () => {
    notifySuccess('Domain changed successfully! Reloading the page...');
};


const fetchUsersError = () => {
    notifyError('Unable to fetch Users!');
};

const updateUserProfileSuccess = () => {
    notifySuccess('User profile updated successfully!');
};
const updateUserProfileError = () => {
    notifyError('Unable to update User profile!');
};


const createUserSuccess = () => {
    notifySuccess('User created successfully!');
};
const createUserError = () => {
    notifyError('Unable to create User!');
};

const deleteUserSuccess = () => {
    notifySuccess('User deleted successfully!');
};
const deleteUserError = () => {
    notifyError('Unable to delete User!');
};


const updateUserPermissionsSuccess = () => {
    notifySuccess('User permissions updated successfully!');
};
const updateUserPermissionsError = () => {
    notifyError('Unable to update User permissions!');
};


export const notification = {

    success: notifySuccess,
    error: notifyError,

    newUnconfirmedDeliveries: newUnconfirmedDeliveries,

    logoutInProcess: logoutInProcess,

    fetchSessionStateError: fetchSessionStateError,

    fetchSitesError: fetchSitesError,

    fetchSiteDetailsError: fetchSiteDetailsError,
    updateSiteDetailsError: updateSiteDetailsError,
    updateSiteDetailsSuccess: updateSiteDetailsSuccess,

    fetchDeliveriesError: fetchDeliveriesError,
    sendDeliveryRequestSuccess: sendDeliveryRequestSuccess,
    sendDeliveryRequestError: sendDeliveryRequestError,
    sendDeliveryQuerySuccess: sendDeliveryQuerySuccess,
    sendDeliveryQueryError: sendDeliveryQueryError,
    sendDeliveryConfirmationSuccess: sendDeliveryConfirmationSuccess,
    sendDeliveryConfirmationError: sendDeliveryConfirmationError,
    sendDeliveryChangeSuccess: sendDeliveryChangeSuccess,
    sendDeliveryChangeError: sendDeliveryChangeError,

    fetchInvoicesError: fetchInvoicesError,

    fetchStockError: fetchStockError,
    sendStockQuerySuccess: sendStockQuerySuccess,
    sendStockQueryError: sendStockQueryError,
    sendStockConfirmationSuccess: sendStockConfirmationSuccess,
    sendStockConfirmationError: sendStockConfirmationError,

    fetchTransactionsError: fetchTransactionsError,

    sendContactMessageSuccess: sendContactMessageSuccess,
    sendContactMessageError: sendContactMessageError,

    fetchDomainsError: fetchDomainsError,
    updateUserDomainError: updateUserDomainError,
    updateUserDomainSuccess: updateUserDomainSuccess,

    fetchUsersError: fetchUsersError,
    updateUserProfileSuccess: updateUserProfileSuccess,
    updateUserProfileError: updateUserProfileError,
    createUserSuccess: createUserSuccess,
    createUserError: createUserError,
    deleteUserSuccess: deleteUserSuccess,
    deleteUserError: deleteUserError,
    updateUserPermissionsSuccess: updateUserPermissionsSuccess,
    updateUserPermissionsError: updateUserPermissionsError,
};
