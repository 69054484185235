import { ApiCallerWithUserManager } from "./ApiCallerWithUserManager";

export class ApiFileDownloader {
    private caller: ApiCallerWithUserManager;

    constructor(baseUrl: string) {
        this.caller = new ApiCallerWithUserManager(baseUrl);
    }

    download(url: string, queryParameters?: {}): Promise<void> {
        return this.caller.downloadFile(url, queryParameters)
            .then(async response => {

                return ({
                    blob: await response.blob(),
                    filename: this.getFileNameFromContentDispostionHeader(response.headers.get('content-disposition'))
                });
            })
            .then(blobWithFileName => {
                if (!blobWithFileName.filename) {
                    throw new Error('There is no file received from the server');
                }
                const url = window.URL.createObjectURL(blobWithFileName.blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = blobWithFileName.filename;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again 
            });
    }

    private getFileNameFromContentDispostionHeader(
        contentDisposition: string | null,
    ): string | undefined {
        const standardPattern = /filename=(["']?)(.+)\1/i;
        const wrongPattern = /filename=([^"'][^;"'\n]+)/i;

        if (contentDisposition) {
            if (standardPattern.test(contentDisposition)) {
                const match = contentDisposition.match(standardPattern);
                if (match && match.length > 2) {
                    return match[2];
                }
            }

            if (wrongPattern.test(contentDisposition)) {
                const match = contentDisposition.match(wrongPattern);
                if (match && match.length > 1) {
                    return match[1];
                }
            }
        }
    }
}