import { Dispatch } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { ApplicationState } from "../store/reducers";


class DomainChangeService {

    COOKIE_NAME = '.siteportal.domain_id.synch';

    NOT_SET = '__NOT_SET__';

    private storeInCookie(domainId: string | null) {
        const options: Cookies.CookieAttributes = {
            sameSite: 'Strict'
        };

        Cookies.set(this.COOKIE_NAME, domainId ?? '', options);
    }

    private getDomainIdFromCookie(): string {
        const result = Cookies.get(this.COOKIE_NAME);

        if (result === undefined) {
            return this.NOT_SET;
        }

        return result;
    }

    setThunk(domainId: string | null){

        const storeInCookieFunc = this.storeInCookie.bind(this);

        return function (dispatch: Dispatch<any>) {
            storeInCookieFunc(domainId);
        }
    }

    monitorDomainChangeThunk() {

        const getFromCookieFunc = this.getDomainIdFromCookie.bind(this);

        const NOT_SET = this.NOT_SET;

        return function (dispatch: Dispatch<any>, getState: () => ApplicationState) {

            const domainIdFromCookie = getFromCookieFunc();

            const domainId = getState().status.domainId;


            if (domainIdFromCookie === NOT_SET || domainId === NOT_SET) {
                return;
            }

            if (domainIdFromCookie !== domainId) {
                // console.log(`cook: ${domainIdFromCookie}, dom: ${domainId}`);
                window.location.reload();
            }
        }
    }
}

export const domainChangeService = new DomainChangeService();