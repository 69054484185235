import { Col, Row, TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { deliverySlice, fetchDeliveriesThunk } from '../../../store/slices/DeliverySlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import PageTitle from '../PageTitle';
import DeliveryRequestButtonModalForm from './DeliveryRequestButtonModalForm';
import DeliveryTable from './DeliveryTable';
import DeliveryTableFilters from './DeliveryTableFilters';

export default function () {

	const dispatch = useDispatch();

	const reduxData = useSelectorShallow(s => s.deliveries);

	function onTableChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) {
		dispatch(deliverySlice.actions.setPageIndex(pagination.current ?? 1));

		// console.log(sorter);

		let sortOrder = sorter.field && sorter.order
			? sorter.field
			: null;

		if (sorter.order === 'descend') {
			sortOrder += '_desc';
		}

		dispatch(deliverySlice.actions.setSortOrder(sortOrder));

		dispatch(fetchDeliveriesThunk());
	}

	useEffect(() => {
		dispatch(fetchDeliveriesThunk());
		// eslint-disable-next-line
	}, []);

	function refreshPage() {
		dispatch(fetchDeliveriesThunk());
	}


	return (<>
		<PageTitle title="Deliveries" />
		<div>
			<Row>
				<Col span={4}>
				</Col>
				<Col span={16}>
					<h1 style={{ textAlign: 'center' }}>Manage Deliveries</h1>
				</Col>
				<Col span={4} style={{ textAlign: 'right', paddingTop: '0.5em' }}>
					<DeliveryRequestButtonModalForm refresh={refreshPage} />
				</Col>
			</Row>

			<br />
			<DeliveryTableFilters
				afterDate={reduxData.afterDate}
				beforeDate={reduxData.beforeDate}
				status={reduxData.status}
				search={reduxData.search}
				onAfterDateChanged={value => {
					dispatch(deliverySlice.actions.setAfterDate(value));
					dispatch(fetchDeliveriesThunk());
				}}
				onBeforeDateChanged={value => {
					dispatch(deliverySlice.actions.setBeforeDate(value));
					dispatch(fetchDeliveriesThunk());
				}}
				onSearchChange={value => {
					dispatch(deliverySlice.actions.setSearch(value));
					dispatch(fetchDeliveriesThunk());
				}}
				onStatusChanged={value => {
					dispatch(deliverySlice.actions.setProduct(value));
					dispatch(fetchDeliveriesThunk());
				}}
			/>
			<DeliveryTable results={reduxData.data} inProgress={reduxData.inProgress} onTableChange={onTableChange} pageIndex={reduxData.pageIndex} pageSize={reduxData.pageSize} refresh={refreshPage} />

		</div>

	</>);
}