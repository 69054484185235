import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { fetchSplashMessagesAdminThunk, splashMessageAdminSlice } from '../../../store/slices/SplashMessageAdminSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import PageTitle from '../PageTitle';
import SplashMessageCreateButton from './SplashMessageCreateButton';
import SplashMessageTable from './SplashMessageTable';

export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.splashMessagesAdmin);

    function refresh() {
        dispatch(fetchSplashMessagesAdminThunk());
    }

    useEffect(() => {
        if(reduxData.data.items.length === 0){
            refresh();
        }
    // eslint-disable-next-line
    }, []);

    return (<>
        <PageTitle title="Splash Messages Admin" />
        <div>
            <Row>
                <Col span={4}>
                </Col>
                <Col span={16}>
                    <h1 style={{ textAlign: 'center' }}>Manage Splash Messages</h1>
                </Col>
                <Col span={4} style={{ textAlign: 'right', paddingTop: '0.5em' }}>
                    <SplashMessageCreateButton refresh={refresh} />
                </Col>
            </Row>

            <br />

            <SplashMessageTable currentPage={reduxData.page} pageSize={reduxData.pageSize} paginatedData={reduxData.data}
                inProgress={reduxData.inProgress} onPageChange={currentPage => dispatch(splashMessageAdminSlice.actions.setPage(currentPage))} />

        </div>
    </>);
}