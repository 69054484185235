import { Button } from 'antd';
import React, { useState } from 'react';
import { UserType } from '../../../models/Users';
import { FontAwesome } from '../../FontAwesome';
import UserCreateModalForm from './UserCreateModalForm';

interface Props {
    disabled: boolean;
    refresh(): void;
    text: string;
    action: UserType;
}

export default function (props: Props) {

    const [show, setShow] = useState(false);

    return (<div style={{ textAlign: 'right' }}>
        <Button disabled={props.disabled} type='primary' onClick={() => setShow(true)}><FontAwesome name='plus' /> {props.text}</Button>
        <br />
        <br />
        {!props.disabled && show && <UserCreateModalForm show={show} close={() => setShow(false)} title={props.text} refresh={props.refresh} action={props.action} />}
    </div>);
}