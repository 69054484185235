import { Button, Col, Row, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { notification } from '../../../notification';
import DateIntervalPickerFrom from '../../Utils/DateIntervalPickerFrom';
import DateIntervalPickerUntil from '../../Utils/DateIntervalPickerUntil';

export default function () {

    const [startDate, setStartDate] = useState<string>(moment().add(-6, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'));
    const [type, setType] = useState<string>('DeliveryRequested')

    const [downloadInProgress, setDownloadInProgress] = useState(false);

    async function download() {
        if (!downloadInProgress) {
            try {
                setDownloadInProgress(true);
                await api.downloadReportUsageLog(type, startDate, endDate);

            } catch (error) {
                notification.error('Can\'t download the file');
            }
            finally {
                setDownloadInProgress(false);
            }
        }
    }

    return (<div>
        <h2>Usage logs</h2>
        <p>
            You can download here the usage logs in .csv file format.
        </p>
        <Row gutter={10}>
            <Col span={4}>
                <Select style={{ width: '100%' }} placeholder='Choose a type...' onChange={value => setType(value?.toString() ?? '')} value={type} >
                    <Select.Option key='DeliveryRequested' value='DeliveryRequested'>Delivery requested</Select.Option>
                    <Select.Option key='DeliveryConfirmed' value='DeliveryConfirmed'>Delivery confirmed</Select.Option>
                    <Select.Option key='StockBalanceConfirmed' value='StockBalanceConfirmed'>Stock balance confirmed</Select.Option>
                    <Select.Option key='UserAdded' value='UserAdded'>User added</Select.Option>
                    <Select.Option key='SelfBillPageOpened' value='SelfBillPageOpened'>Self bill page opened</Select.Option>
                    <Select.Option key='SelfBillDownloaded' value="SelfBillDownloaded">Self bill downloaded</Select.Option>
                    <Select.Option key='UserLoggedIn' value='UserLoggedIn'>User logged in</Select.Option>
                </Select>
            </Col>
            <Col>
                <DateIntervalPickerFrom format="DD-MMM-YYYY" value={startDate} placeholder="From..." untilDate={endDate} onChange={value => setStartDate(value ?? '')} />
            </Col>
            <Col>
                <DateIntervalPickerUntil format="DD-MMM-YYYY" value={endDate} placeholder="Until..." fromDate={startDate} onChange={value => setEndDate(value ?? '')} />
            </Col>
            <Col >
                <Button onClick={download}>Download as CSV</Button>
            </Col>
        </Row>

    </div>);
}