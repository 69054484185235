import { Table } from 'antd';
import React from 'react';
import { StockRecord } from '../../../models/Stock';
import StockConfirmationButton from './StockConfirmationButton';
import StockNegativeNumber from './StockNegativeNumber';
import StockNumber from './StockNumber';
import StockQueryButtom from './StockQueryButtom';
import StockStatus from './StockStatus';

interface Props {
    refresh(): void;
    items: StockRecord[];
    inProgress: boolean;
}
export default function (props: Props) {
    const columns = [{
        title: ' ',
        key: 'status',
        className: 'status-col',
        render: (text: string, record: StockRecord) => <StockStatus record={record} />
    }, {
        title: 'Site No.',
        dataIndex: 'siteNumber',
        key: 'siteNumber',
        sorter: (a: StockRecord, b: StockRecord) => Number(a.siteNumber) - Number(b.siteNumber),
    }, {
        title: 'Site Name',
        dataIndex: 'siteName',
        key: 'siteName',
        sorter: (a: StockRecord, b: StockRecord) => a.siteName.toLowerCase().localeCompare(b.siteName.toLowerCase()),
    }, {
        title: 'Open Stock',
        dataIndex: 'openStock',
        key: 'openStock',
        render: (text: string, record: StockRecord) => <StockNumber value={record.openStock} />,
        className: 'right-aligned-col',
        sorter: (a: StockRecord, b: StockRecord) => a.openStock - b.openStock,
    }, {
        title: 'Delivered',
        dataIndex: 'delivered',
        key: 'delivered',
        render: (text: string, record: StockRecord) => <StockNumber value={record.delivered} />,
        className: 'right-aligned-col',
        sorter: (a: StockRecord, b: StockRecord) => a.delivered - b.delivered,
    }, {
        title: 'Stock Credits',
        dataIndex: 'stockCredits',
        key: 'stockCredits',
        render: (text: string, record: StockRecord) => <StockNumber value={record.stockCredits} />,
        className: 'right-aligned-col',
        sorter: (a: StockRecord, b: StockRecord) => a.stockCredits - b.stockCredits,
    }, {
        title: 'Drawings',
        dataIndex: 'drawings',
        key: 'drawings',
        render: (text: string, record: StockRecord) => <StockNegativeNumber value={record.drawings} />,
        className: 'right-aligned-col',
        // sorter: (a,b) => a - b,
        sorter: (a: StockRecord, b: StockRecord) => b.drawings - a.drawings,
    }, {
        title: 'Stock Debits',
        dataIndex: 'stockDebits',
        key: 'stockDebits',
        render: (text: string, record: StockRecord) => <StockNegativeNumber value={record.stockDebits} />,
        className: 'right-aligned-col',
        // sorter: (a,b) => a - b,
        sorter: (a: StockRecord, b: StockRecord) => b.stockDebits - a.stockDebits,
    }, {
        title: 'Current Stock',
        dataIndex: 'currentStock',
        key: 'currentStock',
        render: (text: string, record: StockRecord) => (<StockNumber value={record.currentStock} />),
        className: 'right-aligned-col',
        sorter: (a: StockRecord, b: StockRecord) => a.currentStock - b.currentStock,
    }, {
        title: 'Action',
        className: 'action-col centered-col',
        render: (text: string, record: StockRecord) => {
            return (<>
                <StockQueryButtom record={record} refresh={props.refresh} />
                <StockConfirmationButton record={record} refresh={props.refresh} />
            </>);
        }
    }];


    return (<Table columns={columns} dataSource={props.items} rowKey="id" loading={props.inProgress} bordered />);
}