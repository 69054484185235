import { Menu } from 'antd';
import { useAuth } from 'oidc-react';
import React, { useState } from 'react';
import appSettings from '../../../backend/appSettings';
import { useUserInfo } from '../../../backend/userInfoHook';
import { notification } from '../../../notification';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import DomainChooser from '../Domains/DomainChooser';

interface Props {
	close(): void;
}

export default function (props: Props) {

	const [showDomainChooser, setShowDomainChooser] = useState(false);

	const auth = useAuth();

	const userInfo = useUserInfo();

	const domainId = useSelectorShallow(s => s.status.domainId);

	const fullName = userInfo.fullName;

	const isAdministrator = userInfo.isAdmin;

	const changePasswordUrl = appSettings.identityServerUrl?.concat('/PasswordChange/Change');

	const onClick = ({ key }: { key: React.Key }) => {
		switch (key) {
			case 'logout':
				props.close();
				notification.logoutInProcess();
				auth?.signOutRedirect();
				break;
			case 'admin':
				props.close();
				setShowDomainChooser(true);
				break;
		}
	};

	let userSigninInfo = `Signed in as ${fullName}`;

	return (<>
		<Menu onClick={onClick} className='profile-menu' style={{}} >
			<Menu.Item className="profile-menu-text" key='userInfo'>{userSigninInfo}</Menu.Item>
			<Menu.Divider />
			{isAdministrator && <><Menu.Item key="admin">Administrator settings</Menu.Item><Menu.Divider /></>}
			<Menu.Item key="changePassword" title="You will be redirected to the Password Change page"><a href={changePasswordUrl}>Change password</a></Menu.Item>
			<Menu.Divider />
			<Menu.Item key="logout">Sign out</Menu.Item>
		</Menu>
		{isAdministrator && <DomainChooser show={showDomainChooser} close={() => setShowDomainChooser(false)}  domainId={domainId} />}
	</>);
}