import { TablePaginationConfig } from "antd";

export function getTransactionsPagination (totalItems: number, pageIndex: number, itemsPerPage: number, hasMoreThanLimit: boolean): TablePaginationConfig {
    return {
        total: totalItems,
        current: pageIndex,
        pageSize: itemsPerPage,
        showSizeChanger: false,
        showTotal: (total, range) => {
            if (total === 0) {
                return '0 items';
            }
            if (total === 1) {
                return '1 item';
            }

            let totalText = total.toString();
            if (hasMoreThanLimit) {
                totalText = totalText + '+';
            }

            return `Items ${range[0]}-${range[1]} of ${totalText} items`;
        }
    }
}