import { Checkbox, Col, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';

interface Props {

}

export default function (props: Props) {
    return (<FormItem label='Facilities' style={{ marginTop: '2em' }} name="facilities">
        <Checkbox.Group>
            <Row>
                <Col span={8}><Checkbox value="cafe">Cafe</Checkbox></Col>
                <Col span={8}><Checkbox value="parking">Parking</Checkbox></Col>
                <Col span={8}><Checkbox value="petrol">Petrol</Checkbox></Col>
                <Col span={8}><Checkbox value="shop">Shop</Checkbox></Col>
                <Col span={8}><Checkbox value="overnightAccom">Overnight Accom.</Checkbox></Col>
                <Col span={8}><Checkbox value="gasOil">Gas Oil</Checkbox></Col>
                <Col span={8}><Checkbox value="carWash">Car Wash</Checkbox></Col>
                <Col span={8}><Checkbox value="hours24">24 Hours</Checkbox></Col>
                <Col span={8}><Checkbox value="adBlue">Ad Blue</Checkbox></Col>
                <Col span={8}><Checkbox value="toilet">Toilet</Checkbox></Col>
                <Col span={8}><Checkbox value="tyres">Tyres</Checkbox></Col>
                <Col span={8}><Checkbox value="hgv">HGV</Checkbox></Col>
                <Col span={8}><Checkbox value="phone">Telephone</Checkbox></Col>
                <Col span={8}><Checkbox value="ulsd">ULSD</Checkbox></Col>
                <Col span={8}><Checkbox value="lubeOil">Lube Oil</Checkbox></Col>
                <Col span={8}><Checkbox value="smart">SMART</Checkbox></Col>
            </Row>
        </Checkbox.Group>
    </FormItem>);
}