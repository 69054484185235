import { EditFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { DeliveryRecord } from '../../../models/Deliveries';
import DeliveryChangeModalForm from './DeliveryChangeModalForm';

interface Props {
    record: DeliveryRecord;
    refresh(): void;
}

export default function (props: Props) {

    const [show, setShow] = useState(false);

    if (props.record.isActive && props.record.isFuture) {
        return (<>
            <Tooltip title="Request a change">
                <Button shape='circle' type='primary' icon={<EditFilled />} onClick={() => setShow(true)} style={{ marginRight: '5px' }} />
            </Tooltip>
            {show && <DeliveryChangeModalForm record={props.record} show={show} close={() => setShow(false)} refresh={props.refresh} />}
        </>
        );
    } else {
        return (
            <Button shape='circle' type='primary' icon={<EditFilled />} disabled style={{ marginRight: '5px' }} />
        );
    }
}