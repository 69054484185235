import { DatePicker } from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { dateMoment } from '../../backend/DateMoment';

interface Props {
    value?: string;
    fromDate?: string;
    onChange(value?: string): void;
    format: string;
    placeholder: string;
    allowClear?: boolean;
}

export default function (props: Props) {

    const value = dateMoment(props.value).date;

    function isDisabledBeforeDate(date: Moment | null) {

        const fromDate = dateMoment(props.fromDate).date;

        if(date && fromDate){
            return date.valueOf() < fromDate.valueOf();
        }

        return false;
    }

    function onChange(value: Moment | null){
        if(!value){
            props.onChange();
        }else {
            props.onChange(dateMoment(value).toDateString());
        }
    }

    return (<DatePicker format={props.format} disabledDate={isDisabledBeforeDate}  placeholder={props.placeholder} value={value} onChange={onChange} allowClear={props.allowClear} />);
}