import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../backend/SitePortalStaticApi";
import { UsersResults } from "../../models/Users";
import { notification } from "../../notification";

export const userSlice = createSlice({
    name: 'users',
    initialState: {
        inProgress: false,
        data: {
            administrators: [],
            managers: [],
            users: []
        } as UsersResults
    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setData(state, action: PayloadAction<UsersResults>) { state.data = action.payload }
    }
});

export function fetchUsersThunk() {
    return function (dispatch: Dispatch) {
        dispatch(userSlice.actions.startLoading());

        return api.fetchUsers()
            .then(response => response.json())
            .then((results: UsersResults) => {
                dispatch(userSlice.actions.setData(results));
            })
            .catch(reason => notification.fetchUsersError())
            .finally(() => dispatch(userSlice.actions.endLoading()));
    }
}