import { Button, Card, Col, Row } from 'antd';
import React, { useState } from 'react';
import { FontAwesome } from '../../FontAwesome';
import PageTitle from '../PageTitle';
import ContactForm, { TeamName } from './ContactForm';



export default function () {

    const [modalState, setModalState] = useState({ show: false, team: '' as TeamName | ''});

    function openForm(emailPrefix: TeamName){
        setModalState({ show: true, team: emailPrefix});
    }

    function closeForm(){
        setModalState({show: false, team: ''});
    }

    return (
        <div>
            <PageTitle title="Contact" />
            <h1 style={{ textAlign: 'center' }}>Contact UK Fuels</h1>

            {/* {renderContactModal(visibleModal)} */}
            <br />
            <div style={{ padding: '30px', maxWidth: '1100px', margin: 'auto' }}>
                <Row  gutter={16} style={{ paddingBottom: '16px' }}>
                    <Col span={8} className='contact-card-col'>
                        <Card bordered={true} title='Deliveries Team' className='contact-card'>
                            <p className='flex-full-height'>
                                For any queries relating to an order.
							</p>
                            <br />
                            <p className='contact-card-phone'>
                                Phone: 01270 655 703
							</p>
                            <br />
                            <Button type='primary' onClick={() => openForm('deliveries')}>
                                <FontAwesome name='comment-o' /> Contact the Deliveries Team
							</Button>
                        </Card>
                    </Col>
                    <Col span={8} className='contact-card-col'>
                        <Card bordered={true} title='Site Development Team' className='contact-card'>
                            <p className='flex-full-height'>
                                For all non-transactional site related queries.
							</p>
                            <br />
                            <p className='contact-card-phone'>
                                Phone: 01270 607 372
							</p>
                            <br />
                            <Button type='primary' onClick={() => openForm('sitedevelopment')}>
                                <FontAwesome name='comment-o' /> Contact the Site Development Team
							</Button>
                        </Card>
                    </Col>
                    <Col span={8} className='contact-card-col'>
                        <Card bordered={true} title='Stock Audit Team' className='contact-card'>
                            <p className='flex-full-height'>
                                For queries relating to stock balances, stock discrepancies or any reconciliation questions.
							</p>
                            <br />
                            <p className='contact-card-phone'>
                                Phone: 01270 655 794
							</p>
                            <br />
                            <Button type='primary' onClick={() => openForm('stockaudit')}>
                                <FontAwesome name='comment-o' /> Contact the Stock Audit Team
							</Button>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={10} offset={7}>
                        <Card style={{ textAlign: 'center' }}>
                            <Button type='primary' href='https://www.ukfuels.co.uk/resources/files/OperationsManual_11122018.pdf' target='_blank'>
                                <FontAwesome name='file-text' />Please click here to access your Operations Manual
                            </Button>
                        </Card>
                    </Col>
                </Row>

                <br />
                <br />

                
                    <ContactForm team={modalState.team} close={closeForm} show={modalState.show} />
                

            </div>
        </div>
    );
}