import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useAppDispatch as useDispatch } from '../../store/hooks';
import { api } from '../../backend/SitePortalStaticApi';
import { SplashMessageNotificationItemData } from '../../models/SplashMessageNotifications';
import { notification } from '../../notification';
import { splashMessageNotificationSlice } from '../../store/slices/SplashMessageNotificationSlice';
import MultilineText from '../Utils/MultilineText';

interface Props {
    item: SplashMessageNotificationItemData;
}

export default function (props: Props) {

    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(false);

    async function confirm() {
        if (!inProgress) {

            try {
                setInProgress(true);
                await api.postSplashMessageNotificationConfirmation(props.item.id);

                setInProgress(false);
                dispatch(splashMessageNotificationSlice.actions.confirm(props.item.id));

            } catch (error) {
                notification.error('Can\'t confirm Notification');
                setInProgress(false);
            }
        }

    }

    const inProgressTitle = inProgress
        ? 'Confirming the notification'
        : undefined;

    return (<tr className='sp-notification-item'>
        <td className='sp-notification-body'>
            <MultilineText text={props.item.body} />
        </td>
        <td className="sp-notification-confirm">
            <Button title={inProgressTitle} disabled={inProgress} style={{ color: "rgb(0,120,0)" }} onClick={confirm}><CheckCircleFilled /> OK</Button>
        </td>
    </tr>);
}