import { AuthContextProps, AuthProviderProps, UserManager } from "oidc-react";
import appSettings from "./appSettings";

export const userManager = new UserManager({
    authority: appSettings.identityServerUrl as string,
    client_id: 'rogers_spa',
    redirect_uri: appSettings.frontendUrl as string,
    silent_redirect_uri: appSettings.frontendUrl + '/silent_renew.html',
    post_logout_redirect_uri: appSettings.identityServerUrl + '/account/login',
    response_type: 'code',
    scope: 'openid roles profile offline_access email main_api siteportal_additional',
    loadUserInfo:  true,
    automaticSilentRenew: true ,
    monitorSession: true,
    checkSessionIntervalInSeconds: 2,
    revokeTokensOnSignout: true,
    revokeTokenTypes: ['access_token', 'refresh_token'],
});

// userManager.events.addSilentRenewError(cc => {
//     userManager.startSilentRenew();
// });


// Log.logger = console;
// Log.level = Log.DEBUG;

export const oidcConfig: AuthProviderProps = {
    onBeforeSignIn: () => {
    },
    onSignIn: async (user: any) => {
        window.location.hash = '';
        window.location.search = '';
    },
    // authority: 'http://localhost:5000',
    // clientId: 'rogers_spa',
    // redirectUri: 'http://localhost:3000',
    // scope: 'openid roles profile offline_access siteportal_additional email main_api',
    // postLogoutRedirectUri: 'http://localhost:5000/account/login',

    // automaticSilentRenew: true,
    // loadUserInfo: true,
    userManager: userManager
};


export function oidcPostInit(authContext: AuthContextProps) {
    userManager.events.addUserSignedOut(() => {
        authContext.signOutRedirect();
    });
}