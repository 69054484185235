import { Checkbox, Table } from 'antd';
import React from 'react';
import { SiteDescriptor } from '../../../models/SitesWithStockBalances';

interface Props {
    sites: SiteDescriptor[];
}

export default function (props: Props) {

    const columns = [{
        title: 'Site No.',
        dataIndex: 'siteNumber',
        key: 'siteNumber',
        sorter: (a: SiteDescriptor, b: SiteDescriptor) => Number(a.siteNumber) - Number(b.siteNumber),
    }, {
        title: 'Site Name',
        dataIndex: 'siteName',
        key: 'siteName',
        sorter: (a: SiteDescriptor, b: SiteDescriptor) => a.siteName.toLowerCase().localeCompare(b.siteName.toLowerCase()),
    }, {
        title: 'Grant Access',
        dataIndex: 'access',
        key: 'access',
        className: 'centered-col',
        render: (text: string, record: SiteDescriptor) => (
            <div>
                <Checkbox value={record.siteNumber} />
            </div>
        ),

    }];

    const pagination = props.sites.length > 10
        ? {
            showTotal: (total: number, range: number[]) => {
                if (total === 0) {
                    return '0 items';
                }
                if (total === 1) {
                    return '1 item';
                }
                if (range[0] === range[1]) {
                    return `Item ${range[0]} of ${total} items`;
                }
                return `Items ${range[0]}-${range[1]} of ${total} items`;
            }
        }
        : false;

    return (<Table columns={columns} dataSource={props.sites} pagination={pagination} />);
}