import { Col, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { User, UserEditData } from '../../../models/Users';
import { notification } from '../../../notification';

interface Props {
    record: User;
    refresh(): void;
    title: string;
    show: boolean;
    close(): void;
}

export default function (props: Props) {

    const [form] = useForm();

    const [inProgress, setInProgress] = useState(false);

    async function submit(values: UserEditData) {
        try {
            setInProgress(true);
            values.id = props.record.id;

            await api.postUserEdit(values);

            notification.updateUserProfileSuccess();

            form.resetFields();
            setInProgress(false);
            props.refresh();
            props.close();

        } catch (error) {
            notification.updateUserProfileError();
            setInProgress(false);
        }
    }

    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title={props.title} okText="Save" confirmLoading={inProgress} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={submit}>
            <Row>
                <Col span={12}>
                    <FormItem label='First Name' style={{ marginRight: '1em' }}
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter a first name' }]} 
                        initialValue={props.record.firstName}>
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label='Last Name'
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter a last name' }]}
                        initialValue={props.record.lastName}>
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <FormItem label='Position' style={{ marginRight: '1em' }} name="position" initialValue={props.record.position}>
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label='Company' name="company" initialValue={props.record.company}>
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <FormItem label='Email' style={{ marginRight: '1em' }} name="email"
                        rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]} initialValue={props.record.email}>
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label='Phone' name="phone" initialValue={props.record.phone}>
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
            </Row>

        </Form>
    </Modal>);
}