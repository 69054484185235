import { AuthenticatedApiCaller } from "./authenticatedApiCaller";

export default class ApiCallerWithBaseUrl{

    baseUrl: string;

    constructor(baseUrl: string){
        this.baseUrl = baseUrl;
    }

    caller = new AuthenticatedApiCaller();

    postJson(url: string, token: string | null, payload: any): Promise<Response>{

        url = this.baseUrl + url;

        return this.caller.postJson(url, token, payload);
    }

    getJson(url: string, token: string | null, queryParameters?: {}): Promise<Response>{
        url = this.baseUrl + url;
        
        return this.caller.getJson(url, token, queryParameters);
    }

    downloadFile(url: string, token: string | null, queryParameters?: {}): Promise<Response>{

        url = this.baseUrl + url;

        return this.caller.downloadFile(url, token, queryParameters);
    }
}