import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import {  StockBalanceInfo } from '../../../../models/SitesWithStockBalances';

interface Props {
    stockBalance: StockBalanceInfo;
}

export default function (props: Props) {
    return (<div style={{ fontSize: '0.9em', paddingLeft: '18px' }}>
        <Row>
            <Col span={18}>
                <span>Estimated opening Stock Balance As Of: {moment(props.stockBalance.date).format('DD MMM YYYY')}</span>
            </Col>
            <Col span={6}>
                <span>{displayDecimal(props.stockBalance.currentStock, 'ltrs')}</span>
            </Col>
        </Row>
        <Row>
            <Col span={18}>
                <span>Average Daily Throughput</span>
            </Col>
            <Col span={6}>
                <span>{displayDecimal(props.stockBalance.averageThroughput, 'ltrs')}</span>
            </Col>
        </Row>
        <Row>
            <Col span={18}>
                <span>Estimated Current No. of Days Stock</span>
            </Col>
            <Col span={6}>
                <span>{displayDecimal(props.stockBalance.daysStock, 'days')}</span>
            </Col>
        </Row>
        <Row>
            <Col span={18}>
                <span><strong>The Maximum Stock You May Order Today Is</strong></span>
            </Col>
            <Col span={6}>
                <span><strong>{displayDecimal(props.stockBalance.maxOrder, 'ltrs')}</strong></span>
            </Col>
        </Row>
        <Row>
            <Col span={21}>
                <br />
                <p>If you have further enquiry regarding your order amount, please <a href="/contact">contact the Deliveries Team</a>.</p>
            </Col>
        </Row>
    </div>);
}

function displayDecimal(value?: number, unit?: string): string {
    if (value !== undefined && value !== null) {
        return value.toLocaleString() + ' ' + unit;
    }

    return '';
}