import { Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';

interface Props {
    name: string;
    label: string;
    initialValue: string;
}

const Option = Select.Option;

export default function (props: Props) {
    return (
        <FormItem 
            label={props.label}
            name={props.name} 
            rules={[{ required: true, message: 'Please select a time slot' }]}
            initialValue={props.initialValue}>
                <Select style={{ width: '12em' }}>
                    <Option value='morning'>Morning</Option>
                    <Option value='afternoon'>Afternoon</Option>
                    <Option value='evening'>Evening</Option>
                </Select>
        </FormItem>
    );
}