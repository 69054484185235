import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Domain{
    id: string;
    name: string;
}

export const domainSlice = createSlice({
    name: 'domains',
    initialState: {
        domainList: null as Domain[] | null,
        inProgress: false,
    },
    reducers: {
        set(state, action: PayloadAction<Domain[]>){
            state.domainList = action.payload;
            state.inProgress = false;
        },
        start(state){
            state.inProgress = true;
        },
        end(state){
            state.inProgress = false;
        }
    }
});