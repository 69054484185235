import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../backend/SitePortalStaticApi";
import { SiteRecord } from "../../models/Sites";
import { notification } from "../../notification";

export const siteSlice = createSlice({
    name: 'sites',
    initialState: {
        inProgress: false,
        data: [] as SiteRecord[],
        filteredData: [] as SiteRecord[],
        searchString: ''
    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setData(state, action: PayloadAction<SiteRecord[]>) {
            state.data = action.payload;
            state.filteredData = filter(state.searchString, action.payload);
        },
        setSearch(state, action: PayloadAction<string>){
            state.searchString = action.payload;
            state.filteredData = filter(action.payload, state.data);
        },
        setSite(state, action: PayloadAction<SiteRecord>){
            const index = state.data.findIndex(s => s.siteNumber === action.payload.siteNumber);

            state.data[index] = action.payload;
        }

    }
});


export function fetchSitesThunk() {
    return function (dispatch: Dispatch) {
        return api.fetchSites()
            .then(response => response.json())
            .then((results: SiteRecord[]) => {
                dispatch(siteSlice.actions.setData(results));
            })
            .catch(reason => notification.fetchSitesError())
            .finally(() => dispatch(siteSlice.actions.endLoading()));
    }
}


function filter(search: string, input: SiteRecord[]): SiteRecord[] {
    if (!search || search.trim() === '') {
        return input;
    }

    return input.filter(x => {
        return x.siteNumber.toLowerCase().includes(search) ||
            x.siteName.toLowerCase().includes(search) ||
            x.address1.toLowerCase().includes(search) ||
            x.town.toLowerCase().includes(search) ||
            x.county.toLowerCase().includes(search) ||
            x.postCode.toLowerCase().includes(search) ||
            x.phone.toLowerCase().includes(search)
            ;
    });
}