import React from 'react';
import NegativeNumber from '../../Utils/NegativeNumber';

interface Props {
    value: number
}

export default function (props: Props) {
    if(props.value < 0){
        return (<NegativeNumber value={props.value} />)
    }

    return (<div>{props.value.toLocaleString('en-GB')}</div>)
}