import { Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { fetchSiteWithStockBalancesThunk } from '../../../store/slices/SiteWithStockBalanceSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';

interface Props {
    name: string;
    label: string;
    initialValue?: string;
}

export default function (props: Props) {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.sitesWithStockBalances);

    useEffect(() => {
        if(reduxData.data.length === 0 && !reduxData.hasError){
            dispatch(fetchSiteWithStockBalancesThunk());
        }
    // eslint-disable-next-line
    }, []);

    const initialValue = props.initialValue ?? '';

    return (<FormItem label="Site" name={props.name} initialValue={initialValue} rules={[{ required: true, message: 'Please select a Site' }]}>
    <Select>
        {reduxData.data && reduxData.data.map((v) => <Select.Option key={v.site.siteNumber} value={v.site.siteNumber}>{v.site.siteName} — {v.site.town}, {v.site.postCode}</Select.Option>)}
    </Select>
</FormItem>);
}