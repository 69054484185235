import * as React from 'react';
import { NavigateFunction, Route } from 'react-router-dom';
import { Card, Badge, /*Row, Col*/ } from 'antd';
import { FontAwesome } from '../../FontAwesome';

interface MenuCardProps {
	link: string;
	icon: string;
	color?: string;
	backgroundColor?: string;
	badgeCount?: Number;
	children: React.ReactNode;
	navigate: NavigateFunction;
}

export class MenuCard extends React.Component<MenuCardProps, {}> {
	public render() {
		// const { children, link, icon, backgroundColor = '#f4f4f4', color = '#666', badgeCount = 0 } = this.props;
		const { children, icon, badgeCount = 0 } = this.props;

		const renderIcon = (name: string, badgeCount: Number | 0) => {
			if (badgeCount > 0) {
				return <Badge count={String(badgeCount)}>
					<span style={{color: '#fff'}}><FontAwesome name={name} size='4x' fixedWidth /></span>
				</Badge>
			}
			return <FontAwesome name={icon} size='4x' fixedWidth />
		}

		// TODO Why do we get tooltips on our badges?

		return (
			
				<div style={{ cursor: 'pointer' }} onClick={() => {
					//TODO: using useNavigate api
					// history.push(link);
					this.props.navigate(this.props.link);
				}}>
					<Card bordered={true} className='menucard'>
						<h2 style={{ color: '#fff', fontSize: '1.5em', marginTop: '-0.7em', fontWeight: 300, textAlign: 'center' }}>
							{children}
						</h2>
						<div style={{ fontSize: '1.5em', textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>
							{renderIcon(icon, badgeCount)}
						</div>
					</Card>
				</div>
			
		);
	}
}
