import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../backend/SitePortalStaticApi";
import { notification } from "../../notification";
import { ApplicationState } from "../reducers";

export interface TransactionRecord {
	id: number; // TODO id
	isoNumber: string;
	registration: string;
	siteNumber: string;
	siteName: string;
    transactionDateTime: string; // TODO Moment.js
	entryDate: string; // TODO Moment.js
	weekNumber: number;
	isoWeek: number;
	tCode: string;
	mileage: string; // Number or empty string
    quantity: number;
}

export interface TransactionResults {
	totalItems: number;
    hasMoreThanLimit: boolean;
	items: TransactionRecord[];
}

const initialSortOrder = 'id_desc';

export const transactionSlice = createSlice({
    name: 'transactions',
    initialState: {
        inProgress: true,
        data: {
			hasMoreThanLimit: false,
			items: [],
			totalItems: 0,
		} as TransactionResults,
		search: '',
		beforeDate: undefined as string | undefined,
		afterDate: undefined as string | undefined,
		product: 'Diesel',
		sortOrder: initialSortOrder,
		pageSize: 10,
		pageIndex: 1
    },
    reducers:{
		startLoading(state){ state.inProgress = true},
		endLoading(state){ state.inProgress = false},
		setSearch(state, action: PayloadAction<string>){ state.search = action.payload; state.pageIndex = 1;},
		setProduct(state, action: PayloadAction<string>){ state.product= action.payload; state.pageIndex = 1;},
		setBeforeDate(state, action: PayloadAction<string | undefined>){ state.beforeDate = action.payload; state.pageIndex = 1;},
		setAfterDate(state, action: PayloadAction<string | undefined>){ state.afterDate = action.payload; state.pageIndex = 1;},
		setSortOrder(state, action: PayloadAction<string>){ 
			if(action.payload){
                state.sortOrder = action.payload;
            }
            else {
                state.sortOrder = initialSortOrder;
            }
		},
		setPageIndex(state, action: PayloadAction<number>) {state.pageIndex = action.payload},
		setData(state, action: PayloadAction<TransactionResults>){ state.data = action.payload},
    }
});

export function fetchTransactionsThunk() {
    return function (dispatch: Dispatch<any>, getState: () => ApplicationState) {
        
		dispatch(transactionSlice.actions.startLoading());
		const state = getState().transactions;

		return api.fetchTransactions(
			state.pageIndex,
			state.pageSize,
			state.sortOrder,
			state.product,
			state.search,
			state.afterDate,
			state.beforeDate)
			.then(response => response.json())
			.then((results: TransactionResults) => {
				dispatch(transactionSlice.actions.setData(results));
			})
			.catch(reason => notification.fetchTransactionsError())
			.finally(() => dispatch(transactionSlice.actions.endLoading()));
    }
}