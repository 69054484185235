import { Modal } from 'antd';
import React from 'react';
import { useSelectorShallow } from '../../store/utils/useSelectorShallow';
import SplashMessageNotificationItem from './SplashMessageNotificationItem';


export default function () {

    const reduxData = useSelectorShallow(s => s.splashMessageNotifications);

    return (<Modal className="sp-notification-list" open={reduxData.unconfirmedNotifications.length > 0} footer={[]} closable={false}>
        <table>
            <tbody>
                {reduxData.unconfirmedNotifications.map(s => <SplashMessageNotificationItem key={s.id} item={s} />)}
            </tbody>
        </table>
    </Modal>);
}