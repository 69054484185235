import { Button } from 'antd';
import React, { useState } from 'react';
import SplashMessageCreateModalForm from './SplashMessageCreateModalForm';

interface Props {
    refresh(): void;
}

export default function (props: Props) {
    const [show, setShow] = useState(false);


    return (<>
        <Button onClick={() =>setShow(true)} type="primary">
            Create
        </Button>

        {show && <SplashMessageCreateModalForm show={show} close={() => setShow(false)} refresh={props.refresh} />}
    </>);
}