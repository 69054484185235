import { Col, Row, Select } from 'antd';
import React from 'react';
import ClearableTextFilter from '../../Utils/ClearableTextFilter';
import DateIntervalPickerFrom from '../../Utils/DateIntervalPickerFrom';
import DateIntervalPickerUntil from '../../Utils/DateIntervalPickerUntil';
import DeliveryCsvDownloadButton from './DeliveryCsvDownloadButton';

interface Props {
    search: string | null;
    onSearchChange(value: string): void;
    beforeDate?: string;
    onBeforeDateChanged(value?: string): void;
    afterDate?: string;
    onAfterDateChanged(value?: string): void;
    status: string;
    onStatusChanged(value: string): void;
}

const Option = Select.Option;

export default function (props: Props) {

    return (<Row gutter={14} style={{ marginBottom: '20px' }} align="bottom">
        <Col span={5}>
            <ClearableTextFilter value={props.search} onChange={props.onSearchChange} label="Search" />
        </Col>
        <Col>
            <DateIntervalPickerFrom format="DD-MMM-YYYY" placeholder="From..." value={props.afterDate} untilDate={props.beforeDate} onChange={props.onAfterDateChanged} allowClear />
        </Col>
        <Col>
            <DateIntervalPickerUntil format="DD-MMM-YYYY" placeholder="Until..." value={props.beforeDate} fromDate={props.afterDate} onChange={props.onBeforeDateChanged} allowClear />
        </Col>
        <Col span={4}>

            <Select style={{ width: '9.5em' }} value={props.status} onChange={props.onStatusChanged}>
                <Option value='any'>Any</Option>
                <Option value='unconfirmed'>Unconfirmed</Option>
                <Option value='due'>Due</Option>
                <Option value='requested'>Requested</Option>
                <Option value='changed'>Changed</Option>
                <Option value='confirmed'>Confirmed</Option>
                <Option value='credits'>Stock Credits</Option>
                <Option value='debits'>Stock Debits</Option>
            </Select>

        </Col>
        <Col span={5}>
            <DeliveryCsvDownloadButton status={props.status} search={props.search} afterDate={props.afterDate} beforeDate={props.beforeDate} />
        </Col>
    </Row>);
}