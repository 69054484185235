import React from 'react';
import { Row, Col } from 'antd';
import { MenuCard } from './MenuCard';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import PageTitle from '../PageTitle';
import { useNavigate } from 'react-router-dom';

// import { Status } from './Status';

// TODO Consider replacing this page with a dashboard?

export default function(){

	const data = useSelectorShallow(s => ({
		deliveryCount: s.status.deliveryCount,
		stockCount: s.status.stockCount
	}));

	const navigate = useNavigate();

	return (
		<div style={{ padding: '30px', maxWidth: '1000px', margin: 'auto' }}>

			<PageTitle title="SitePortal - UK Fuels" withoutPostfix  />

			<Row gutter={16} style={{ paddingBottom: '16px' }}>
				<Col span={8}>
					<MenuCard link='/sites' icon='map-marker' navigate={navigate}>
						Manage Sites
					</MenuCard>
				</Col>
				<Col span={8}>
					<MenuCard link='/deliveries' icon='calendar' badgeCount={data.deliveryCount} navigate={navigate}>
						Manage Deliveries
					</MenuCard>
				</Col>
				<Col span={8}>
					<MenuCard link='/stock' icon='bar-chart' badgeCount={data.stockCount} navigate={navigate}>
						Manage Stock
					</MenuCard>
				</Col>
			</Row>
			<Row gutter={16} style={{ paddingBottom: '16px' }}>
				<Col span={8}>
					<MenuCard link='/transactions' icon='credit-card' navigate={navigate}>
						View Transactions
					</MenuCard>
				</Col>
				<Col span={8}>
					<MenuCard link='/documents' icon='file-o' navigate={navigate}>
						Manage Documents
					</MenuCard>
				</Col>
				<Col span={8}>
					<MenuCard link='/users' icon='user' navigate={navigate}>
						Manage Users
					</MenuCard>
				</Col>
			</Row>



		</div>
	);
}