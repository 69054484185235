import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { useUserInfo } from '../../../backend/userInfoHook';
import { fetchUsersThunk } from '../../../store/slices/UserSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import PageTitle from '../PageTitle';
import UserCreateButton from './UserCreateButton';
import UserTable from './UserTable';

const TabPane = Tabs.TabPane;

export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.users);

    const userInfo = useUserInfo();

    const isAdministrator = userInfo.isAdmin;

    const isManager = userInfo.isAdmin || userInfo.isManager;

    function refreshPage() {
        dispatch(fetchUsersThunk());
    }

    useEffect(() => {
        refreshPage();
        // eslint-disable-next-line
    }, []);

    return (<>
        <PageTitle title="Users" />
        <h1 style={{ textAlign: 'center' }}>Manage Users</h1>
        <br />

        <div>
            <Tabs defaultActiveKey='users' /*onChange={this.handleTabChange}*/>
                <TabPane tab='Users' key='users'>
                    <UserCreateButton text="Create a User" disabled={!isManager} refresh={refreshPage} action="new-user" />

                    <UserTable inProgress={reduxData.inProgress} items={reduxData.data.users} refresh={refreshPage}
                        editText="Edit user" deleteText="Delete User" 
                        permissionsText="Edit user permissions"
                        showActionsAndLastLogin={isAdministrator || isManager} />
                </TabPane>
                <TabPane tab='Managers' key='managers'>
                    <UserCreateButton text="Create a Manager" disabled={!isAdministrator} refresh={refreshPage} action="new-manager" />
                    {/* <UsersTable
                        // title={() => 'Managers'}
                        dataSource={results.managers}
                        pagination={pagination}
                        loading={{ delay: 300, size: 'large', spinning: this.props.showSpinner }}
                        onEditUser={this.handleEditUser}
                        onDeleteUser={this.handleDeleteUser}
                        onEditUserPermissions={this.handlePermissions}
                        showActions={isAdministrator}
                    /> */}
                    <UserTable inProgress={reduxData.inProgress} items={reduxData.data.managers} refresh={refreshPage} 
                        editText="Edit manager" deleteText="Delete manager"
                        showActionsAndLastLogin={isAdministrator}
                        />


                </TabPane>
                {isAdministrator && <TabPane tab="Administrators" key="administrators">
                    <UserCreateButton text="Create an Administrator" disabled refresh={refreshPage} action="admin" />

                    <UserTable inProgress={reduxData.inProgress} items={reduxData.data.administrators} refresh={refreshPage} 
                        editText="Edit administrator" 
                        showActionsAndLastLogin={isAdministrator}
                        />

                </TabPane>}
            </Tabs>
        </div>
    </>);
}