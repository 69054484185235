import { Button, Col, Row, Tooltip } from 'antd';
import { FilterValue, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { fetchTransactionsThunk, transactionSlice } from '../../../store/slices/TransactionSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import { FontAwesome } from '../../FontAwesome';
import PageTitle from '../PageTitle';
import TransactionsFilter from './TransactionsFilter';
import TransactionsTable from './TransactionsTable';

export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.transactions);

    function onTableChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) {
        dispatch(transactionSlice.actions.setPageIndex(pagination.current ?? 1));

        let sortOrder = sorter.field && sorter.order
			? sorter.field
			: null;
            
        if (sorter.order === 'descend') {
            sortOrder += '_desc';
        }

        dispatch(transactionSlice.actions.setSortOrder(sortOrder));

        dispatch(fetchTransactionsThunk());
    }

    useEffect(() => {
        dispatch(fetchTransactionsThunk());
        // eslint-disable-next-line
    }, []);


    return (
        <div>
            <PageTitle title="Transactions" />
            <Row>
                <Col span={8}>
                </Col>
                <Col span={8}>
                    <h1 style={{ textAlign: 'center' }}>View Transactions</h1>
                </Col>
                <Col span={8} style={{ textAlign: 'right', paddingTop: '0.7em' }}>
                    <Tooltip title='External link' placement='bottom'>
                        <Button type='primary' onClick={() => window.open('http://www.enteratransaction.co.uk/')}>
                            <FontAwesome name='external-link' /> Enter a missing Transaction</Button>
                    </Tooltip>
                </Col>
            </Row>
            <br />
            <TransactionsFilter
                afterDate={reduxData.afterDate}
                beforeDate={reduxData.beforeDate}
                product={reduxData.product}
                search={reduxData.search}
                onAfterDateChanged={value => {
                    dispatch(transactionSlice.actions.setAfterDate(value));
                    dispatch(fetchTransactionsThunk());
                }}
                onBeforeDateChanged={value => {
                    dispatch(transactionSlice.actions.setBeforeDate(value));
                    dispatch(fetchTransactionsThunk());
                }}
                onSearchChange={value => {
                    dispatch(transactionSlice.actions.setSearch(value));
                    dispatch(fetchTransactionsThunk());
                }}
                onProductChanged={value => {
                    dispatch(transactionSlice.actions.setProduct(value));
                    dispatch(fetchTransactionsThunk());
                }}
            />
            <TransactionsTable results={reduxData.data} inProgress={reduxData.inProgress} onTableChange={onTableChange} pageIndex={reduxData.pageIndex} pageSize={reduxData.pageSize} />
        </div>
    );
}