import ApiCallerWithBaseUrl from "./ApiCallerWithBaseUrl";
import { userManager } from "./openIdConnectInit";

export class ApiCallerWithUserManager {

    private caller: ApiCallerWithBaseUrl;

    constructor(baseUrl: string) {
        this.caller = new ApiCallerWithBaseUrl(baseUrl);
    }

    getJson(url: string, queryParameters?: {}): Promise<Response> {
        return this.callInternal(
            token => this.caller.getJson(url, token, queryParameters)
        );
    }

    postJson(url: string, payload: any): Promise<Response> {
        return this.callInternal(
            token => this.caller.postJson(url, token, payload)
        );
    }

    downloadFile(url: string, queryParameters?: {}): Promise<Response>{
        return this.callInternal(
            token => this.caller.downloadFile(url, token, queryParameters)
        );
    }

    private callInternal(callFunc: (token: string) => Promise<Response>): Promise<Response> {
        return new Promise<Response>((resolve, reject) => {
            userManager.getUser()
                .then(user => {
                    if (!user || user.expired || !user.access_token) {

                        // Trying to reauthenticate the user.
                        // Because of a bug in the oidc-client, it stops renewing the access_token 
                        // if the user was offline after during the expiration.
                        return userManager.signinSilent()
                            .then(user => {
                                return user;
                            },
                            reason => {

                                // console.warn('----------- Cant do silent signing -----------');
                                // console.warn(reason);
                                // debugger;
                                const error = reason as Error;
                                if (error && error.message && error.message === 'Network Error') {
                                    reject(new Error('Can\'t access the server'));
                                }
                                else{
                                    userManager.signoutRedirect();
                                    reject(new Error('User is not authenticated'));
                                }
                                
                                return null;
                            });
                    }
                    else{
                        return Promise.resolve(user);
                    }
                })
                .then(user => {
                    if (!user) {
                        return;
                    }

                    if (user.expired) {
                        reject(new Error("There is no authenticated user"));
                        return;
                    }

                    callFunc(user.access_token)
                        .then(response => {
                            if (response.status === 401) {
                                userManager.signoutRedirect();
                            }
                            else if (!response.ok) {
                                // TODO: it would be great to implement api error handling, like processing validation messages from the server
                                reject(Error(response.statusText));
                            }

                            resolve(response);
                        }, reason => {
                            reject(reason);
                        })
                        ;
                });
        });
    }
}