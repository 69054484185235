import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { StockRecord } from '../../../models/Stock';
import { FontAwesome } from '../../FontAwesome';
import StockQueryModalForm from './StockQueryModalForm';

interface Props {
    record: StockRecord;
    refresh(): void;
}

export default function (props: Props) {

    const [show, setShow] = useState(false);


    if (props.record.isUnconfirmed) {
        return (<>
            <Tooltip title='Raise a query'>
                <Button shape='circle' type='primary' onClick={() => setShow(true)} style={{ marginRight: '5px' }}>
                    <FontAwesome name='comment-o' />
                </Button>
            </Tooltip>

            {show && <StockQueryModalForm show={show} close={() => setShow(false)} record={props.record} refresh={props.refresh} />}
        </>);


    }

    return (<Button shape='circle' type='primary' style={{ marginRight: '5px' }} disabled>
        <FontAwesome name='comment-o' />
    </Button>);
}