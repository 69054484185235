import moment from "moment";
import { Moment } from "moment";

export class DateMoment{
    date?: Moment;

    /**
     *
     */
    constructor(date?: Moment) {
        this.date = date;
    }

    toDateString(){
        if(this.date){
            return this.date.format('YYYY-MM-DD');
        }

        return '';
    }

    toString(){
        return this.toDateString();
    }
}

export function dateMoment(input?: string | Moment | number): DateMoment{
    if(input){
        
        if(typeof input === 'number'){
            return new DateMoment(moment.unix(input));
        }

        if(input === ''){
            return new DateMoment();
        }
        
        return new DateMoment(moment(input));
    }

    return new DateMoment();
}