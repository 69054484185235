import { Select } from 'antd';
import React from 'react';
import { DocumentType } from '../../../models/Documents';

const Option = Select.Option;


interface Props {
    value: DocumentType | null;
    onChange(value: DocumentType): void;
    valueSet: DocumentType[];
}


export default function (props: Props) {

    return (<Select value={props.value ?? undefined} onChange={props.onChange} placeholder="Document Type"  disabled={props.valueSet.length === 0} style={{width: '100%'}}>
        {props.valueSet.map(item => <Option key={item} value={item}>{getName(item)}</Option>)}     
    </Select>);
}

function getName(type: DocumentType): string {
    if(type === DocumentType.WeeklyStatement){
        return 'Weekly Statements';
    }

    if(type === DocumentType.SelfBill){
        return 'Self-bill Invoices';
    }

    return 'Monthly Statements';
}