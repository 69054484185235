import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { notification } from '../../../notification';

interface Props {
    show: boolean;
    close(): void;
    refresh(): void;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    async function submit(values: {body: string}) {
        try {
            setInProgress(true);

            await api.postSplashMessageCreate(values.body);

            notification.success('Splash Message has been successfully created');

            form.resetFields();
            setInProgress(false);

            props.close();
            props.refresh();

        } catch (error) {
            notification.error(`Can't create Splash message: ${error}`);
            setInProgress(false);
        }
    }

    const [form] = useForm();

    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title="Create a Splash Message" okText="Create" confirmLoading={inProgress} onOk={form.submit}>
        <Form form={form} layout="vertical" onFinish={submit}>


            <FormItem label='Message Text' name="body"
                rules={[{ required: true, whitespace: true, message: 'Please input the splash message text' },
                { max: 4000, message: 'Maximum allowed length of the message is 4000 characters.' }]}>
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 20 }} />
            </FormItem>
        </Form>
    </Modal>);
}