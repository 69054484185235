import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { domainChangeService } from "../../backend/DomainChangeService";
import { api } from "../../backend/SitePortalStaticApi";
import { notification } from "../../notification";
import { ApplicationState } from "../reducers";

export const statusSlice = createSlice({
  name: 'status',
  initialState: {
    deliveryCount: 0,
    stockCount: 0,
    domainId: domainChangeService.NOT_SET as null | string,
  },
  reducers: {
    decrementDelivery(state) {
      state.deliveryCount--;
    },
    decrementStock(state) {
      state.stockCount--;
    },
    setCounts(state, action: PayloadAction<{ delivery: number, stock: number }>) {
      state.deliveryCount = action.payload.delivery;
      state.stockCount = action.payload.stock;
    },
    setDomainId(state, action: PayloadAction<string | null>) {
      state.domainId = action.payload;
    }
  }
});

export function fetchStatusThunk() {
  return function (dispatch: Dispatch<any>, getState: () => ApplicationState) {

    return api.fetchSessionState2()
      .then(result => result.json())
      .then(data => {

        dispatch(statusSlice.actions.setCounts({
          delivery: data.deliveryCount,
          stock: data.stockCount,
        }));

        const newDomainId = data.domainId;
 
        dispatch(statusSlice.actions.setDomainId(newDomainId ?? ''));
        dispatch(domainChangeService.setThunk(newDomainId ?? ''));

      })
      .catch(reason => {
        dispatch(statusSlice.actions.setCounts({ delivery: 0, stock: 0 }));
        // console.warn(reason);
        notification.fetchSessionStateError();
      });
  }

}