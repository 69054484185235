import { Button } from 'antd';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { notification } from '../../../notification';
import { FontAwesome } from '../../FontAwesome';

interface Props {
    isoWeek: string;
}

export default function (props: Props) {

    const [downloadInProgress, setDownloadInProgress] = useState(false);

    async function download() {
        if (!downloadInProgress) {
            try {
                setDownloadInProgress(true);
                await api.downloadStockCsv(props.isoWeek);

            } catch (error) {
                notification.error('Can\'t download the CSV file');
            }
            finally {
                setDownloadInProgress(false);
            }
        }
    }

    const isDisabled = !props.isoWeek || props.isoWeek === '';

    return (<Button  onClick={download} disabled={isDisabled}>
        <FontAwesome name='download' /> Download as CSV</Button>);
}