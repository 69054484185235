import { Col, Row } from 'antd';
import React from 'react';
import ClearableTextFilter from '../../Utils/ClearableTextFilter';

interface Props {
    search: string;
    onSearchChange(value: string): void;
}

export default function (props: Props) {
    return (<Row gutter={12} style={{marginBottom: '16px'}}>
        <Col span={8}>

            <ClearableTextFilter label="Search" value={props.search} onChange={props.onSearchChange} />

        </Col>
    </Row>);
}