import React, { useState } from 'react';
import { Avatar, Dropdown, Tooltip } from 'antd';
import AccountMenu from './AccountMenu';
import { FontAwesome } from '../../FontAwesome';
import { useAuth } from 'oidc-react';
import styles from './ProfileDropDown.module.css';



export default function () {
    let backgroundCol = 'rgb(195, 195, 195)';
    let caretCol = 'rgb(210, 210, 210)';

    const [show, setShow] = useState(false);

    function visibleChange(flag: boolean){
        setShow(flag);
    }

    function close(){
        setShow(false);
    }

    const auth = useAuth();

    let usernameAbbreviation = '';

    if(auth && auth.userData){
        usernameAbbreviation = `${getFirstChar(auth.userData.profile.given_name)} ${getFirstChar(auth.userData.profile.family_name)}`;        
    }
    
    return (<Tooltip title='Profile settings' placement='bottomLeft'>
        <Dropdown overlay={<AccountMenu close={close} />} trigger={['click']} open={show} onOpenChange={visibleChange}>
            <span className={`ant-dropdown-link avatar-dropdown ${styles.dropdown}`} >
                <Avatar shape='square' style={{ backgroundColor: backgroundCol, /*fontWeight: 500,*/ verticalAlign: 'middle' }}>{usernameAbbreviation}</Avatar>
                <FontAwesome name='caret-down' style={{ verticalAlign: 'middle', color: caretCol, marginLeft: '0.2em' }} />
            </span>
        </Dropdown>
    </Tooltip>);
}

function getFirstChar(input?: string | null): string{
    if(input && input.length > 0){
        return input[0];
    }

    return '';
}