import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { fetchSiteWithStockBalancesThunk } from '../../../store/slices/SiteWithStockBalanceSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';

interface Props {
    value: string | null;
    valueSet: string[];
    onChange(value: string): void;
}

export default function (props: Props) {
    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.sitesWithStockBalances);

    useEffect(() => {
        if(reduxData.data.length === 0 && !reduxData.hasError){
            dispatch(fetchSiteWithStockBalancesThunk());
        }
    // eslint-disable-next-line
    }, []);

    return (<Select style={{width: '100%'}} value={props.value ?? ''} onChange={props.onChange}>

        {reduxData.data && reduxData.data.filter(s => props.valueSet.includes(s.site.siteNumber)).map((v) =>  <Select.Option key={v.site.siteNumber} value={v.site.siteNumber} 
        title={`${v.site.siteName} - ${v.site.town}, ${v.site.postCode}` }
        >{v.site.siteName} — {v.site.town}, {v.site.postCode}</Select.Option>)}
    </Select>);
}