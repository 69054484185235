import { Table } from 'antd';
import React from 'react';
import { PaginatedList } from '../../../models/PaginatedList';
import { SplashMessageAdminListItem } from '../../../models/SplashMessages';
import MultilineText from '../../Utils/MultilineText';

const Column = Table.Column;


interface Props {
    inProgress: boolean;
    paginatedData: PaginatedList<SplashMessageAdminListItem>;
    onPageChange(page: number): void;
    currentPage: number;
    pageSize: number;
}

export default function (props: Props) {
    return (<Table bordered dataSource={props.paginatedData.items}
        loading={{ delay: 300, size: 'large', spinning: props.inProgress }}
        rowKey={s => s.id.toString()}
        pagination={{ onChange: props.onPageChange, defaultPageSize: props.pageSize, total: props.paginatedData.totalItems, current: props.currentPage }}
    >
        <Column title="Text" dataIndex="body" render={text => <MultilineText text={text} />}  />
        <Column title="Created By" dataIndex="createdBy" />
        <Column title="Creation Time" dataIndex="creationTimeUtc" width="27%" />
    </Table>);
}