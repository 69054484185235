const appSettings = {
    frontendUrl: getEnvironmentVariable('FRONTEND_URL'),
    identityServerUrl: getEnvironmentVariable('IDENTITY_SERVER_URL'),
    backendUrl: getEnvironmentVariable('BACKEND_URL') ?? '',
    useFakeBackend: getEnvironmentVariable('USE_FAKE_BACKEND') === "true",
};

if(appSettings.backendUrl === ''){
    throw new Error('BACKEND_URL setting is required');
}

export default appSettings;


function getEnvironmentVariable(name: string, defaultValue?: string): string | undefined{
    let result = process.env['REACT_APP_'.concat(name)];

    if(result !== undefined){
        return result;
    }

    return defaultValue;
}