import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { useUserInfo } from '../../../backend/userInfoHook';
import { User } from '../../../models/Users';
import UserDeleteButtonModal from './UserDeleteButtonModal';
import UserEditButton from './UserEditButton';
import UserPermissionsButton from './UserPermissionsButton';

interface Props {
    items: User[];
    inProgress: boolean;
    refresh(): void;
    editText?: string;
    deleteText?: string;
    permissionsText?: string;
    showActionsAndLastLogin: boolean;
}

export default function (props: Props) {

    const userInfo = useUserInfo();


    let columns = [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: User, b: User) => {
            var res = a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
            if (res === 0) {
                res = a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
            }
            return res;
        },
        render: (text: string, record: User) => <>{record.firstName + ' ' + record.lastName}</>
    }, {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        sorter: (a: User, b: User) => a.company.toLowerCase().localeCompare(b.company.toLowerCase()),
    }, {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        sorter: (a: User, b: User) => a.position.toLowerCase().localeCompare(b.position.toLowerCase()),
    }, {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a: User, b: User) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
    }, {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        sorter: (a: User, b: User) => a.phone.toLowerCase().localeCompare(b.phone.toLowerCase()),
    }, {
        title: 'Last Login',
        dataIndex: 'lastLogin',
        key: 'lastLogin',
        sorter: (a: User, b: User) => a.lastLogin.toLowerCase().localeCompare(b.lastLogin.toLowerCase()),
        render: (text: string) => {
            const date = moment(text).format('DD-MMM-YY');
            if (date === '01-Jan-01') {
                // return (<span>Never</span>);
                return (<></>);
            }
            return (
                <div className='nowrap'>
                    <span style={{ width: '5.5em', display: 'inline-block' }}>{date}&nbsp;</span>
                    <span>{moment(text).format('HH:mm')}</span>
                </div>
            );
        }
    }, {
        title: 'Action',
        className: 'action-col centered-col',
        render: (text: string, record: User) => <>

            { props.deleteText
                && <UserDeleteButtonModal text={props.deleteText} refresh={props.refresh} record={record}
                    disabled={userInfo.id === record.id} />}

            { props.editText
                && <UserEditButton text={props.editText} refresh={props.refresh} record={record} />}

            { props.permissionsText 
                && <UserPermissionsButton text={props.permissionsText} refresh={props.refresh} record={record} />}

        </>
        // {
        //     // console.log(record);

        //     const deleteTooltip = record.isManager ? 'Delete manager' : 'Delete user';
        //     const editTooltip = record.isManager ? 'Edit manager details' : 'Edit user details';

        //     const renderDeleteButton = () => {
        //         if (record.email == currentUserEmail) {
        //             // Cannot delete self
        //             return (
        //                 <Button shape='circle' type='primary' icon='cross' disabled style={{ marginRight: '5px' }} />
        //             );
        //         }
        //         return (
        //             <Tooltip title={deleteTooltip}>
        //                 <Button shape='circle' type='primary' icon='cross' onClick={() => onDeleteUser(record)} style={{ marginRight: '5px' }} />
        //             </Tooltip>
        //         );
        //     };

        //     const renderPermissionsButton = () => {
        //         if (record.isManager) {
        //             return null;
        //         }
        //         return (
        //             <Tooltip title='Edit user permissions' placement='topRight'>
        //                 <Button shape='circle' type='primary' icon='unlock' onClick={() => onEditUserPermissions(record)} style={{ marginLeft: '5px' }} />
        //             </Tooltip>
        //         );
        //     };

        //     return (
        //         <div>
        //             {renderDeleteButton()}
        //             <Tooltip title={editTooltip} placement='topRight'>
        //                 <Button shape='circle' type='primary' icon='edit' onClick={() => onEditUser(record)} /*style={{ marginRight: '5px' }}*/ />
        //             </Tooltip>
        //             {renderPermissionsButton()}
        //         </div>
        //     );
        // }
    }];

    if (!props.showActionsAndLastLogin) {
        columns = columns.slice(0, columns.length - 2)
    }

    const pagination = props.items.length > 10
        ? { total: props.items.length }
        : false;

    return (<>
        <Table columns={columns} dataSource={props.items} loading={props.inProgress} pagination={pagination} bordered rowKey="id" />
    </>);
}