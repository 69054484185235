import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { fetchSitesThunk, siteSlice } from '../../../store/slices/SiteSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import PageTitle from '../PageTitle';
import SiteTable from './SiteTable';
import SiteTableFilter from './SiteTableFilter';

export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.sites);

    function refreshPage() {
        dispatch(fetchSitesThunk());
    }

    useEffect(() => {

        if (reduxData.data.length === 0) {
            refreshPage();
        }
        // eslint-disable-next-line
    }, []);



    return (<>
        <PageTitle title="Sites" />

        <div>
            <h1 style={{ textAlign: 'center' }}>Manage Sites</h1>
            <br />

            <SiteTableFilter
                search={reduxData.searchString}
                onSearchChange={value => dispatch(siteSlice.actions.setSearch(value))}
            />

            <SiteTable refresh={refreshPage} inProgress={reduxData.inProgress} items={reduxData.filteredData} />
        </div>

    </>);
}