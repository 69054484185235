import { Col, Row, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { useUserInfo } from '../../../backend/userInfoHook';
import { DeliveryRecord, DeliveryResults } from '../../../models/Deliveries';
import DeliveryChangeButton from './DeliveryChangeButton';
import DeliveryConfirmationButton from './DeliveryConfirmationButton';
import DeliveryDeleteButton from './DeliveryDeleteButton';
import DeliveryQueryButton from './DeliveryQueryButton';
import DeliveryDate from './TableCells/DeliveryDate';
import DeliveryOrderNumber from './TableCells/DeliveryOrderNumber';
import DeliveryQuantity from './TableCells/DeliveryQuantity';
import DeliveryStatus from './TableCells/DeliveryStatus';
import DeliverySubStatus from './TableCells/DeliverySubStatus';
import styles from './DeliveryTable.module.css';





interface Props {
    results: DeliveryResults,
    pageSize: number,
    pageIndex: number,
    inProgress: boolean;
    onTableChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<DeliveryRecord> | SorterResult<any>[]): void;
    refresh(): void;

}

export default function (props: Props) {

    const userInfo = useUserInfo();

    const pagination = {
        total: props.results.totalItems,
        current: props.pageIndex,
        pageSize: props.pageSize,
        showSizeChanger: false,
        showTotal: (total: number, range: any) => {
            if (total === 0) {
                return '0 items';
            }
            if (total === 1) {
                return '1 item';
            }
            if (range[0] === range[1]) {
                return `Item ${range[0]} of ${total} items`;
            }
            return `Items ${range[0]}-${range[1]} of ${total} items`;
        }
    }

    // const [changeState, setChangeState] = useState({
    //     show: false,
    //     record: null as DeliveryRecord | null
    // });

    const columns = [{
        title: 'Status',
        key: 'status',
        className: 'delivery-status-col',
        render: (text: string, record: DeliveryRecord) => (
            <Row>
                <Col span={12} className='main'>
                    <DeliveryStatus record={record} />
                </Col>
                <Col span={12}>
                    <DeliverySubStatus record={record} />
                </Col>
            </Row>
        )
    }, {
        title: 'Order No.',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        sorter: true,
        render: (text: string, record: DeliveryRecord) => (<DeliveryOrderNumber record={record} text={text} />)
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: true,
        render: (text: string, record: DeliveryRecord) => (<DeliveryDate text={text} record={record} />)
    }, {
        title: 'ISO Week',
        dataIndex: 'isoWeek',
        key: 'isoWeek',
        sorter: true
    }, {
        title: 'Site No.',
        dataIndex: 'siteNumber',
        key: 'siteNumber',
        sorter: true
    }, {
        title: 'Site Name',
        dataIndex: 'siteName',
        key: 'siteName',
        sorter: true
    }, {
        title: 'Oil Company',
        dataIndex: 'oilCompany',
        key: 'oilCompany',
        sorter: true
    }, {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        className: 'right-aligned-col',
        sorter: true,
        render: (text: string, record: DeliveryRecord) => (<DeliveryQuantity text={text} record={record} />)
    }, {
        title: 'Ticket No.',
        dataIndex: 'ticketNumber',
        key: 'ticketnumber',
        sorter: true
    }, {
        title: 'Action',
        key: 'actions',
        className: 'action-col centered-col',
        render: (text: string, record: DeliveryRecord) => {
            return (
                <div>
                    <DeliveryChangeButton record={record} refresh={props.refresh} />
                    <DeliveryQueryButton record={record} refresh={props.refresh} />
                    <DeliveryConfirmationButton record={record} refresh={props.refresh} />
                    {userInfo.isSuperAdmin && <DeliveryDeleteButton record={record} refresh={props.refresh} />}
                    {/* {this.renderChangeButton(record)}
                    {this.renderQueryButton(record)}
                    {this.renderConfirmButton(record)} */}
                </div>
            );
        }
    }];


    return (<>
        {/* <DeliveryChangeModalForm show={changeState.show} record={changeState.record} close={() => setChangeState({record: null, show: false})} /> */}
        <Table
            onChange={props.onTableChange}
            columns={columns}
            dataSource={props.results.items}
            bordered={true}
            rowKey="id"
            pagination={pagination}
            loading={{ delay: 300, size: 'large', spinning: props.inProgress }}
            
            rowClassName={(record) => {

                let className = '';

                // NOTE: this logic is a mirror of that can be found in DeliveryStatus.tsx. They should be synched
                if (record.isActive
                    && !record.isRequested
                    && !record.isFuture
                    && !record.hasConfirmation) {
                    className = styles.unconfirmedDelivery;
                }

                return className;
            }}
        />
    </>);
}