import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { notification } from '../../../notification';

export default function () {

    const [form] = useForm();

    const [downloadInProgress, setDownloadInProgress] = useState(false);


    async function downloadReport(values: { dateInterval: Moment[]; emails: string; }) {
        if (!downloadInProgress) {
            try {
                setDownloadInProgress(true);
                await api.downloadReportNotificationConfirmation(values.emails, values.dateInterval.map(s => s.format('YYYY-MM-DD')));

            } catch (error) {
                notification.error('Can\'t download the file');
                // console.error(error);
            }
            finally {
                setDownloadInProgress(false);
            }
        }
    }

    return (<div>
        <h2>Splash Message Report</h2>
        <p>
            You can download here the report about the splash message confirmations in .csv file format.
        </p>
        <Row>
            <Col span={18}>
                <Form form={form} layout='horizontal' autoComplete='off' onFinish={downloadReport}>

                    <Form.Item label='Message creation date range' name="dateInterval"
                        rules={[{ type: "array", min: 1, message: 'Please input a date range ' }]}
                        initialValue={[moment().add(-6, 'day'), moment()]}
                    >
                        <DatePicker.RangePicker  />
                    </Form.Item>
                    <Form.Item label='User emails' name="emails" rules={[{ required: true, message: 'Please input at least one email address' }]}>
                        <Input.TextArea placeholder='You can enter the comma-separated email address of the users...' rows={2} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="default" onClick={() => form.submit()} >Download as CSV</Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>

    </div>);
}