import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { User } from '../../../models/Users';
import UserEditModalForm from './UserEditModalForm';

interface Props {
    refresh(): void;
    record: User;
    text: string;
}

export default function (props: Props) {

    const [show, setShow] = useState(false);


    return (<>
        <Tooltip title={props.text} placement='topRight'>
            <Button shape='circle' type='primary' icon={<EditOutlined />} onClick={() =>  setShow(true)} style={{ marginLeft: '5px' }} />
        </Tooltip>

        {show && <UserEditModalForm record={props.record} title={props.text} show={show} close={() => setShow(false)} refresh={props.refresh} />}
    </>);
}