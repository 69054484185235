import { Button, Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { dateMoment } from '../../../backend/DateMoment';
import { api } from '../../../backend/SitePortalStaticApi';
import { DeliveryRequest } from '../../../models/Deliveries';
import { notification } from '../../../notification';
import { FontAwesome } from '../../FontAwesome';
import SiteWithStockBalancePicker from './StockBalances/SiteWithStockBalancePicker';
import TimeSlotSelector from './TimeSlotSelector';
import VolumeSelector from './VolumeSelector';

interface Props {
    refresh(): void;
}

export default function (props: Props) {

    const [show, setShow] = useState(false);

    const [inProgress, setInProgress] = useState(false);

    const [form] = useForm();


    async function submit(values: DeliveryRequest) {

        try {

            values.date = dateMoment(values.date).toDateString();

            setInProgress(true);

            await api.postDeliveryRequest(values);

            notification.sendDeliveryRequestSuccess();
            setShow(false);
            form.resetFields();
            props.refresh();

        } catch (error) {
            notification.sendDeliveryRequestError();
        }
        finally {
            setInProgress(false);
        }

    }

    return (<>
        <Button type='primary' size='large' onClick={() => setShow(true)}>
            <FontAwesome name='plus' /> Request a Delivery
        </Button>
        <Modal open={show} closable destroyOnClose onCancel={() => setShow(false)}
            title="Request a Delivery"
            okText="Send Request"
            confirmLoading={inProgress}
            onOk={form.submit}
        >
            <Form form={form} layout="vertical" onFinish={submit}>

                <SiteWithStockBalancePicker label="Site" name="siteNumber" selectedSite={form.getFieldValue('siteNumber')} />


                <Row>
                    <Col span={12}>
                        <FormItem label='Delivery Date'
                            name="deliveryDate"
                            rules={[{ required: true, message: "Please enter a date" }]}
                        >
                            <DatePicker format='DD-MMM-YYYY' disabledDate={(date: Moment) => date && date.valueOf() < Date.now()} showToday={false} placeholder='' />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                            <TimeSlotSelector label='Time Slot' initialValue='' name="timeSlot" />
                    </Col>
                </Row>

                <VolumeSelector label='Volume' name="volume" initialValue="" form={form} step={1000}
                 tooltip="This amount may be amended to fit with our stocking policy" />

                <FormItem label='Notes' name="message">
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
                </FormItem>

                <p>
                    <small>
                        Time slot/delivery requests cannot be guaranteed, however we will endeavour to meet your requirements.<br />
    If there are any problems, we will contact you shortly.
    </small>
                </p>
            </Form>

        </Modal>
    </>);
}