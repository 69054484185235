import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { useParams } from 'react-router';
import { api } from '../../../backend/SitePortalStaticApi';
import { SiteRecord } from '../../../models/Sites';
import { notification } from '../../../notification';
import { fetchSitesThunk, siteSlice } from '../../../store/slices/SiteSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import SiteFacilities from './SiteFacilities';

const Option = Select.Option;

export default function () {

    const params = useParams<{ siteNumber: string }>();

    const [form] = useForm();

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.sites);

    const [inProgress, setInProgress] = useState(false);

    const [initialValues, setInitialValues] = useState<SiteRecord | null>(null);


    useEffect(() => {
        if (reduxData.data.length === 0) {
            dispatch(fetchSitesThunk());

        }
        // eslint-disable-next-line
    }, []);


    if (!initialValues) {
        const item = reduxData.data.find(s => s.siteNumber === params.siteNumber);

        if (item) {
            form.setFieldsValue(item);
            setInitialValues(item);
        }
    }

    async function submit(values: SiteRecord) {
        try {
            values.siteNumber = params.siteNumber as string;

            setInProgress(true);

            await api.postSiteUpdate(values);

            // 
            dispatch(siteSlice.actions.setSite(values));

            dispatch(fetchSitesThunk());

            notification.updateSiteDetailsSuccess();
            setInProgress(false);

        } catch (error) {
            notification.updateSiteDetailsError();
            setInProgress(false);
        }
    }


    return (<>
        <Row>
            <Col span={6}>
            </Col>
            <Col span={12}>
                <h1 style={{ textAlign: 'center' }}>Site Number {params.siteNumber}</h1>
            </Col>
            <Col span={6} style={{ textAlign: 'right', paddingTop: '0.7em' }}>
            </Col>
        </Row>

        {!initialValues && <p>Loading...</p>}

        {initialValues && <Form layout="vertical" form={form} onFinish={submit}>
            <Row gutter={64}>
                <Col span={12}>

                    <Row>
                        <Col span={24}>
                            <FormItem label='Site Name' name="siteName"
                                rules={[{ required: true, message: 'Please enter the site name' }]} >
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='Email' style={{ marginRight: '1em' }} name="email"
                                rules={[{ type: 'email', required: true, message: 'Please enter a valid email address' }]}>
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='Telephone' name="phone" rules={[{ required: true, message: 'Please enter a telephone number' }]} >
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label='Address' name="address1" rules={[{ required: true, message: 'Please enter an address line 1' }]} >
                                <Input />
                            </FormItem>
                            <FormItem name="address2" >
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='Town' style={{ marginRight: '1em' }} name="town" rules={[{ required: true, message: 'Please enter a town' }]}>
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='County' name="county" rules={[{ required: true, message: 'Please enter a county' }]} >
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='Post Code' style={{ marginRight: '1em' }} name="postCode" rules={[{ required: true, message: 'Please enter a post code' }]}>
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='Country' name="country" rules={[{ required: true, message: 'Please enter a country' }]}>
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='Preferred Delivery Time' style={{ marginRight: '5em' }} name="deliveryTime">
                                <Select style={{ width: '13em' }}>
                                    <Option value="">{""}</Option>
                                    <Option value='morning'>Morning</Option>
                                    <Option value='afternoon'>Afternoon</Option>
                                    <Option value='evening'>Evening</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='Opening Hours' name="openingHours1">
                                <Input />
                            </FormItem>
                            <FormItem name="openingHours2">
                                <Input />
                            </FormItem>
                            <FormItem name="openingHours3">
                                <Input />
                            </FormItem>
                        </Col>
                    </Row>



                </Col>
                <Col span={12}>
                    <SiteFacilities />

                    <FormItem label='Directions' name="directions">
                        <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
                    </FormItem>

                    <Row>
                        <Col span={12}>
                            <FormItem label='Contact Name' style={{ marginRight: '1em' }} name="contactName">
                                <Input />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label='Contact Position' name="contactPosition">
                                <Input />
                            </FormItem>
                        </Col>
                    </Row>


                    <FormItem label='Contact Details' name="contactDetails">
                        <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
                    </FormItem>

                    <div style={{ textAlign: 'right' }}>
                        <br />
                        <Button type='primary' htmlType='submit' disabled={!initialValues} loading={inProgress}>Save Changes</Button>
                    </div>

                </Col>
            </Row>

        </Form>}
    </>);
}