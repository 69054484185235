import { Col, Row, Select } from 'antd';
import React from 'react';
import ClearableTextFilter from '../../Utils/ClearableTextFilter';
import DateIntervalPickerFrom from '../../Utils/DateIntervalPickerFrom';
import DateIntervalPickerUntil from '../../Utils/DateIntervalPickerUntil';
import TransactionCsvDownloadButton from './TransactionCsvDownloadButton';

interface Props {
    search: string | null;
    onSearchChange(value: string): void;
    beforeDate?: string;
    onBeforeDateChanged(value?: string): void;
    afterDate?: string;
    onAfterDateChanged(value?: string ): void;
    product: string;
    onProductChanged(value: string): void;
}

const Option = Select.Option;

export default function (props: Props) {
    return (<Row gutter={14} style={{marginBottom: '20px'}} align="bottom">
        <Col span={4}>
            <ClearableTextFilter value={props.search} onChange={props.onSearchChange} label="Search" />
        </Col>
        <Col>
            <div>Date From:</div>
            <DateIntervalPickerFrom format="DD-MMM-YYYY" placeholder="From..." value={props.afterDate} untilDate={props.beforeDate} onChange={props.onAfterDateChanged} allowClear />

        </Col>
        <Col>
            <div>Date To:</div>
            <DateIntervalPickerUntil format="DD-MMM-YYYY" placeholder="Until..." value={props.beforeDate} fromDate={props.afterDate} onChange={props.onBeforeDateChanged} allowClear />
        </Col>
        <Col span={4}>
            <div>Product: </div>
            <Select value={props.product} onChange={props.onProductChanged} style={{ width: '100%' }}>
                <Option value='Diesel'>Diesel</Option>
                <Option value='GasOil'>Gas Oil</Option>
                <Option value='LubeOil'>Lube Oil</Option>
                <Option value='UnleadedPetrol'>Unleaded Petrol</Option>
                <Option value='PremiumDiesel'>Premium Diesel</Option>
                <Option value='AdBlue'>Ad Blue</Option>
                <Option value='AdBluePack'>Ad Blue Pack</Option>
                <Option value='CarWash'>Car Wash</Option>
                <Option value='BusWash'>Bus Wash</Option>
                <Option value='SuperUnleaded'>Super Unleaded</Option>
                <Option value='Kerosene'>Kerosene</Option>
                <Option value='Lpg'>Lpg</Option>
                
                <Option value='OtherProducts'>Other Products</Option>
            </Select>
        </Col>
        <Col span={3}>
            <TransactionCsvDownloadButton
                afterDate={props.afterDate}
                beforeDate={props.beforeDate}
                search={props.search}
                product={props.product}
            />
        </Col>
    </Row>);
}