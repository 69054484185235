import { Button } from 'antd';
import React from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { notifyError } from '../../../notification';
import { FontAwesome } from '../../FontAwesome';

interface Props {
    search: string | null;
    beforeDate?: string;
    afterDate?: string;
    product: string;
}

export default function (props: Props) {

    async function  download() {

        try {
            await api.downloadTransactionsCsv(props.search, props.product, props.beforeDate, props.afterDate);
        } catch (error) {
            notifyError('Can\'t download the CSV file');
            console.warn(error);
        }

    }

    return (<Button size="middle" onClick={download} >
            <FontAwesome name='download' /> Download as CSV</Button>);
}