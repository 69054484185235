import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { SiteRecord } from '../../../models/Sites';

interface Props {
    refresh(): void;
    items: SiteRecord[];
    inProgress: boolean;
}

export default function (props: Props) {

    const history = useNavigate();

    function navigateToDetails(siteNumber: string){
        history('/site/'+ siteNumber);
    }

    const columns = [{
        title: 'Site No.',
        key: 'siteNumber',
        dataIndex: 'siteNumber',
        sorter: (a: SiteRecord, b: SiteRecord) => Number(a.siteNumber) - Number(b.siteNumber)
    }, {
        title: 'Site Name',
        key: 'siteName',
        dataIndex: 'siteName',
        sorter: (a: SiteRecord, b: SiteRecord) => a.siteName.toLowerCase().localeCompare(b.siteName.toLowerCase())
    }, {
        title: 'Address ',
        key: 'address1',
        dataIndex: 'address1',
        sorter: (a: SiteRecord, b: SiteRecord) => a.address1.toLowerCase().localeCompare(b.address1.toLowerCase())
    }, {
        title: 'Town',
        key: 'town',
        dataIndex: 'town',
        sorter: (a: SiteRecord, b: SiteRecord) => a.town.toLowerCase().localeCompare(b.town.toLowerCase())
    }, {
        title: 'County',
        key: 'county',
        dataIndex: 'county',
        sorter: (a: SiteRecord, b: SiteRecord) => a.county.toLowerCase().localeCompare(b.county.toLowerCase())

    }, {
        title: 'Post Code',
        key: 'postCode',
        dataIndex: 'postCode',
        sorter: (a: SiteRecord, b: SiteRecord) => a.postCode.toLowerCase().localeCompare(b.postCode.toLowerCase()),
        render: (text: string) => <div className='nowrap'>{text}</div>
    }, {
        title: 'Telephone',
        key: 'phone',
        dataIndex: 'phone',
        sorter: (a: SiteRecord, b: SiteRecord) => a.phone.toLowerCase().localeCompare(b.phone.toLowerCase()),
        render: (text: string) => <div className='nowrap'>{text}</div>
    }, {
        title: 'Action',
        key: 'action',
        render: (text: string, record: SiteRecord) => <>
            <Tooltip title='View/edit Site details' placement='topRight'>
                <Button shape='circle' type='primary' onClick={() => navigateToDetails(record.siteNumber)}>
                    <EditOutlined />
                </Button>

            </Tooltip>
        </>
    }];

    const pagination = props.items.length > 10 
        ? {
            showSizeChanger: false,
            // total: results.totalItems,
            // current: results.pageIndex,
            // pageSize: results.itemsPerPage,
            showTotal: (total: number, range: number[]) => {
                if (total === 0) {
                    return '0 items';
                }
                if (total === 1) {
                    return '1 item';
                }
                if (range[0] === range[1]) {
                    return `Item ${range[0]} of ${total} items`;
                }
                return `Items ${range[0]}-${range[1]} of ${total} items`;
            }
        }
        : false;


    return (<Table columns={columns} dataSource={props.items} loading={props.inProgress} rowKey="siteNumber" pagination={pagination} bordered />);
}