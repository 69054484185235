export interface DocumentRecord {
	id: string;
	siteNumber: string;
	year: number;
	month: number; // For weekly statements is contains the week number (instead of the month)
	fileType: string;
	fileSizeBytes: number;
	type: number;
}

export enum DocumentType{
    SelfBill = 0,
    MonthlyStatement = 1,
    WeeklyStatement = 2
}

export interface DocumentIconInfo{
    name: string;
    color: string;
    icon: string;
}

export type DocumentIndex = Record<number, Record<string, Record<number, Record<number, string>>>>;

export interface MonthWeekInfo{
	number: number;
	name: string;
}