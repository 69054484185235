import React from 'react';
import { Badge } from 'antd';

interface Props{
    count: number | null;
    text: string;
}

export default function (props: Props) {
    if(props.count){
        return (<Badge dot>{props.text}</Badge>);
    }
    return (<div>
        {props.text}
    </div>);
}