import { FormInstance, InputNumber, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface Props {
    label: string;
    name: string;
    initialValue: number | '';
    step: number;
    tooltip?: string;
    requiredValidationMessage?: string;
    form: FormInstance<any>;
}

export default function (props: Props) {

    function inputChange(value: any) {
        props.form.setFields([{name: 'volume', value: value}]);
        props.form.validateFields(['volume'])
    }

    const VolumeLabel = () => <label htmlFor={props.name}>{props.label} {props.tooltip && <Tooltip title={props.tooltip}><QuestionCircleOutlined /></Tooltip>}</label>;

    return (<><FormItem label={ <VolumeLabel />} name={props.name}

        rules={[{ type: 'number', required: true, message: props.requiredValidationMessage}]}
        validateTrigger={['onChange', 'onStep']}
        initialValue={props.initialValue}
    >
        <InputNumber
            min={1000}
            max={38000}
            step={props.step}
            style={{ width: '12em' }}
            onChange={inputChange}
        />
    </FormItem>
    </>);
}


