import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
// import 'antd/dist/antd.css';
import 'antd/dist/antd.css'
import './style.css';
import 'font-awesome/css/font-awesome.min.css';
import AuthProgress from './components/AuthProgress/AuthProgress';
import { useAuth } from 'oidc-react';
import { fetchStatusThunk } from './store/slices/StatusSlice';
import { useAppDispatch as useDispatch } from './store/hooks';

import { oidcPostInit } from './backend/openIdConnectInit';
import { domainChangeService } from './backend/DomainChangeService';
import { fetchSplashMessageNotifications } from './store/slices/SplashMessageNotificationSlice';

function App() {

  const auth = useAuth();


  oidcPostInit(auth);

  let hasAccessToken = true;
  if (!auth?.userData?.access_token) {
    hasAccessToken = false;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasAccessToken) {

      // Fetching the delivery count and the domain id
      const statusIntervalId = setInterval(() => dispatch(fetchStatusThunk()), 20000);
      dispatch(fetchStatusThunk());

      // api.testEmail();

      // Checking whether the Domain has been changed on a different browser tab
      const checkDomainIntervalId = setInterval(() => dispatch(domainChangeService.monitorDomainChangeThunk()), 2000);

      const splashMessageNotificationIntervalId = setInterval(() => dispatch(fetchSplashMessageNotifications()), 30000);
      dispatch(fetchSplashMessageNotifications());

      return () => {
        clearInterval(statusIntervalId);
        clearInterval(checkDomainIntervalId);
        clearInterval(splashMessageNotificationIntervalId);
      };
    }
    // eslint-disable-next-line
  }, [hasAccessToken]);


  return (
  <AuthProgress>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </AuthProgress>
  );
}

export default App;
