import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { DeliveryRecord } from '../../../models/Deliveries';
import { FontAwesome } from '../../FontAwesome';
import DeliveryQueryModalForm from './DeliveryQueryModalForm';

interface Props {
    record: DeliveryRecord;
    refresh(): void;
}

export default function (props: Props) {

    const [show, setShow] = useState(false);
    
    if (props.record.isActive && !props.record.isRequested && !props.record.hasConfirmation) {
        return (<>
            <Tooltip title='Raise a query'>
                <Button shape='circle' type='primary' onClick={() => setShow(true)} style={{ marginRight: '5px' }}>
                    <FontAwesome name='comment-o'/>
                </Button>
            </Tooltip>
            {show && <DeliveryQueryModalForm show={show} close={() => setShow(false)} record={props.record} refresh={props.refresh} />}
        </>);
    } else {
        return (
            <Button shape='circle' type='primary' disabled style={{ marginRight: '5px' }}>
                <FontAwesome name='comment-o'/>
            </Button>
        );
    }
}