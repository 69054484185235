import { Col, Row, Select } from 'antd';
import React from 'react';
import { StockIsoWeekItem } from '../../../models/Stock';
import StockCsvDownloadButton from './StockCsvDownloadButton';

interface Props {
    isoWeek: string;
    onIsoWeekChange(value: string): void;
    isoWeekList: StockIsoWeekItem[];
}

export default function (props: Props) {

    let title = "";
    if(props.isoWeekList.length === 0){
        title= "There are no Stock Items to filter for"
    }

    return (<Row gutter={12} style={{marginBottom: '16px'}}>
        <Col lg={10} title={title}>
            <Select style={{ width: '100%' }} placeholder="Select a week..." disabled={props.isoWeekList.length === 0} onChange={props.onIsoWeekChange} value={props.isoWeek}>
                {props.isoWeekList.map(x => <Select.Option value={x.isoWeek} key={x.isoWeek} title={x.startDate + ' - ' + x.endDate}>
                    Week {x.week}, 20{x.year} <span style={{ fontSize: '0.8em', fontStyle: 'italic', fontWeight: 'bold', paddingBottom: '3px' }}>({x.startDate}-{x.endDate})</span>
                </Select.Option>)}
            </Select>
        </Col>
        <Col lg={4}>
            <StockCsvDownloadButton isoWeek={props.isoWeek} />
        </Col>
    </Row>);
}