import { List } from 'antd';
import React from 'react';
import { DocumentRecord } from '../../../models/Documents';
import DocumentItem from './DocumentItem';

interface Props {
    items: DocumentRecord[];
    inProgress: boolean;
}

export default function (props: Props) {

    return (<List
      loading={props.inProgress}
        grid={{
          gutter: 16,
          xs: 3,
          sm: 4,
          md: 5,
          lg: 6,
          xl: 7,
          xxl: 7,
        }}
        dataSource={props.items}
        renderItem={(record: DocumentRecord) => (
          <List.Item>
            <DocumentItem record={record} />
          </List.Item>
        )}
      />);
}