import React from 'react';

interface Props {
    value: number;
}

export default function (props: Props) {
    let text = Math.abs(props.value).toLocaleString('en-GB');
    
    return <div style={{ color: '#f04134' }}>({text})</div>;
}