import { CheckOutlined, SolutionOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import React from 'react';
import PageTitle from '../PageTitle';
import ReportTabNotificationConfirmations from './ReportTabNotificationConfirmations';
import ReportTabUsageLogs from './ReportTabUsageLogs';
import ReportTabUserDomains from './ReportTabUserDomains';

const TabPane = Tabs.TabPane;

export default function () {
    
    return (<div>
        <PageTitle title="Reports" />
        <h1 style={{ textAlign: 'center' }}>Reports</h1>
        <br />
        <Tabs tabPosition='left'>
            <TabPane tab={<span><UsergroupAddOutlined /> User domains</span>} key="1">
                <ReportTabUserDomains />
            </TabPane>
            <TabPane tab={<span><SolutionOutlined />Usage logs</span>} key="2">
                <ReportTabUsageLogs />
            </TabPane>
            <TabPane tab={<span><CheckOutlined />Splash messages</span>} key="3">
                <ReportTabNotificationConfirmations />
            </TabPane>
        </Tabs>
    </div>);
}