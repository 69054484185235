export class AuthenticatedApiCaller {

    postJson(url: string, token: string | null, payload: any): Promise<Response> {
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    getJson(url: string, token: string | null, queryParameters?: {}): Promise<Response> {

        if (queryParameters) {
            const queryString = this.getQueryParams(queryParameters);
            url = `${url}?${queryString}`;
        }

        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            }
        });
    }

    downloadFile(url: string, token: string | null, queryParameters?: {}): Promise<Response> {
        if (queryParameters) {
            const queryString = this.getQueryParams(queryParameters);
            url = `${url}?${queryString}`;
        }

        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private getQueryParams(queryParameters?: any): string {
        if (!queryParameters) {
            return '';
        }

        const parameters = {} as any;

        for (const key in queryParameters) {
            if (Object.prototype.hasOwnProperty.call(queryParameters, key)) {
                const element = queryParameters[key];

                if (typeof element !== 'undefined' && element !== null) {
                    parameters[key] = element;
                } else {
                    parameters[key] = '';
                }

            }
        }

        return new URLSearchParams(parameters).toString();
    }
}