import { Col, Row } from 'antd';
import React from 'react';
import { DocumentType, MonthWeekInfo } from '../../../models/Documents';
import DocumentMonthChooser from './DocumentMonthChooser';
import DocumentSiteChooser from './DocumentSiteChooser';
import DocumentTypeChooser from './DocumentTypeChooser';
import DocumentYearChooser from './DocumentYearChooser';


interface Props {
    type: DocumentType | null;
    onTypeChange(value: DocumentType): void;
    types: DocumentType[];

    site: string | null;
    sites: string[];
    onSiteChange(value: string): void;

    year: number | null;
    years: number[];
    onYearChange(value: number): void;

    monthOrWeek: number | null;
    monthsOrWeeks: MonthWeekInfo[];
    onMonthOrWeekChange(value: number | null): void;
}

export default function (props: Props) {

    const monthWeekEmptyText = props.type === DocumentType.WeeklyStatement  
        ? 'All Weeks'
        : 'All Months';

    return (<Row style={{marginBottom: '15px'}} gutter={15}>
        <Col lg={5} md={6}>
            <DocumentTypeChooser value={props.type} onChange={props.onTypeChange} valueSet={props.types} />
        </Col>
        <Col xl={8} lg={9} md={10}>
            <DocumentSiteChooser value={props.site} onChange={props.onSiteChange} valueSet={props.sites} />
        </Col>
        <Col>
            <DocumentYearChooser value={props.year} onChange={props.onYearChange} valueSet={props.years} />
        </Col>
        <Col span={4}>
            <DocumentMonthChooser value={props.monthOrWeek} onChange={props.onMonthOrWeekChange} valueSet={props.monthsOrWeeks}
                emptyText={monthWeekEmptyText} />
        </Col>
    </Row>);
}