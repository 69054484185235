import { useAuth } from 'oidc-react';
import React from 'react';
import AuthLoadingIndicator from './AuthLoadingIndicator';

interface Props{
    children: React.ReactNode
}

export default function (props: Props) {
    
    const auth = useAuth();

    // console.log(`Has auth ${Boolean(auth)}`);
    // console.log(`Has auth.UserData ${Boolean(auth?.userData)}`);
    // if(auth && auth.userData){
    //     console.log(`User data: ${JSON.stringify(auth.userData)}`);
    // }

    return (<>
        {(!auth || !auth.userData) && <AuthLoadingIndicator />}
        {auth && auth.userData && <>{props.children}</>}
    </>);
}