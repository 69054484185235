import { Col, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { useUserInfo } from '../../../backend/userInfoHook';
import { notification } from '../../../notification';

export type TeamName = 'deliveries' | 'sitedevelopment' | 'stockaudit';

interface Props {
    team: TeamName | '';
    close(): void;
    show: boolean;
}



export default function (props: Props) {

    const [form] = useForm();

    const [asyncState, setAsyncState] = useState({ inProgress: false, errors: [] });

    const userInfo = useUserInfo();

    let teamNames = {
        deliveries: 'Deliveries Team',
        sitedevelopment: 'Site Development Team',
        stockaudit: 'Stock Audit Team',
        '': ''
    };

    const modalTitle = `Contact the ${teamNames[props.team]}`;

    function submit() {

        setAsyncState({ inProgress: true, errors: [] });
        return form.validateFields()
            .then(values => {
                values['team'] = props.team;
                return api.sendContactMessage(values)
                    .then(() => {
                        notification.sendContactMessageSuccess();
                        form.resetFields();
                        props.close();
                    })
                    .catch(reason => notification.sendContactMessageError())
                    ;
            })
            .catch(() => {})
            .finally(() => {
                setAsyncState({ inProgress: false, errors: [] })
            })
            ;
    }
    
    function onCancel(){
        form.resetFields();
        props.close();
    }

    return (<Modal title={modalTitle} open={props.show} onCancel={onCancel} onOk={submit} confirmLoading={asyncState.inProgress} destroyOnClose>
        <Form form={form}>
            <Form.Item name="team" hidden initialValue={props.team}>
                <Input type="hidden" />
            </Form.Item>
            <Row gutter={10}>
                <Col span={12}>
                    <Form.Item label="Name" name="contactName" initialValue={userInfo.fullName} rules={[{ required: true, message: 'Please enter a name' }]} labelCol={{ span: 24 }}>
                        <Input />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <Form.Item label="Position" name="contactPosition" initialValue={userInfo.position} rules={[{ required: true, message: 'Please enter a position' }]} labelCol={{ span: 24 }}>
                        <Input autoComplete='off' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Telephone" name="contactPhone" initialValue={userInfo.phone} rules={[{ required: true, message: 'Please enter a telephone number' }]} labelCol={{ span: 24 }}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Email" name="contactEmail" initialValue={userInfo.email} rules={[{ required: true, message: 'Please enter a valid email address' }]} labelCol={{ span: 24 }}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Message" name="message" rules={[{ required: true, message: 'Please enter a message' }]} labelCol={{ span: 24 }}>
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>

                </Col>
            </Row>
            <p>
                <small>
                    Please note: to ensure there is an accurate record of your query,
                    it is recommended that you contact the relevant department.
                        </small>
            </p>
        </Form></Modal>);
}