import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../backend/SitePortalStaticApi";
import { StockResults, StockResultsTransformed } from "../../models/Stock";
import { notification } from "../../notification";
import { ApplicationState } from "../reducers";
import { stockSliceLogic } from "../sliceLogics/StockSliceLogic";



export const stockSlice = createSlice({
    name: 'stock',
    initialState: {
        inProgress: true,
        data: {
            isoWeek: '',
            stockRecords: [],
            isoWeeks: []
        } as StockResultsTransformed,
    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setData(state, action: PayloadAction<StockResults>) { 

            
            state.data = {
                ...action.payload,
                isoWeeks: stockSliceLogic.transformIsoWeeks(action.payload.isoWeeks)
            }
         },
        setIsoWeek(state, action: PayloadAction<string>) {state.data.isoWeek = action.payload}
    }
});

export function fetchStocksThunk() {
    return function (dispatch: Dispatch, getState: () => ApplicationState) {
        dispatch(stockSlice.actions.startLoading());
        const state = getState().stocks;

        return api.fetchStocks(state.data.isoWeek)
            .then(response => response.json())
            .then((results: StockResults) => {
                dispatch(stockSlice.actions.setData(results));
            })
            .catch(reason => notification.fetchStockError())
            .finally(() => dispatch(stockSlice.actions.endLoading()));
    }
}