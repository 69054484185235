import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../backend/SitePortalStaticApi";
import { SplashMessageNotificationItemData, SplashMessageNotificationResults, SplashMessageNotificationStateItemData } from "../../models/SplashMessageNotifications";
import { ApplicationState } from "../reducers";

export const splashMessageNotificationSlice = createSlice({
    name: 'splashNotifications',
    initialState: {
        data: [] as SplashMessageNotificationStateItemData[],
        unconfirmedNotifications: [] as  SplashMessageNotificationItemData[]
    },
    reducers: {
        setData(state, action: PayloadAction<SplashMessageNotificationResults>) {
            let newState = state.data.map(s => {
                let newItem = Object.assign({}, s);
                if (action.payload.alreadyConfirmed.some(c => c === s.id)) {
                    newItem.isConfirmed = true;
                }
                return newItem;
            });

            // Merging the new notifications to the state
            let brandNewNotifications = action.payload.items.filter(n => !newState.some(st => st.id === n.id)).map(n =>
                Object.assign({}, n, { isConfirmed: false })
            )

            newState = newState.concat(brandNewNotifications);

            state.data = newState;

            state.unconfirmedNotifications = newState.filter(s => !s.isConfirmed);
        },

        confirm(state, action: PayloadAction<number>){
            const index = state.data.findIndex(s => s.id === action.payload);
            if(index > -1){

                state.data[index].isConfirmed = true;
            }
            
            state.unconfirmedNotifications = state.data.filter(s => !s.isConfirmed);
        }
    }
});

export function fetchSplashMessageNotifications() {
    return function (dispatch: Dispatch, getState: () => ApplicationState) {

        const state = getState().splashMessageNotifications;

        return api.fetchSplashMessageNotifications(
            state.data.filter(s => s.isConfirmed).map(s => s.id),
            state.data.map(s => s.id)
        )
            .then(response => response.json())
            .then((json: SplashMessageNotificationResults) => {
                dispatch(splashMessageNotificationSlice.actions.setData(json));
            })
            .catch(error => {});
    }

}