import { Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { dateMoment } from '../../../backend/DateMoment';
import { api } from '../../../backend/SitePortalStaticApi';
import { DeliveryChange, DeliveryRecord } from '../../../models/Deliveries';
import { notification } from '../../../notification';
import SiteSelector from './SiteSelector';
import TimeSlotSelector from './TimeSlotSelector';
import VolumeSelector from './VolumeSelector';

interface Props {
    record: DeliveryRecord;
    show: boolean;
    close(): void;
    refresh(): void;
}

export default function (props: Props) {

    const [form] = useForm();

    const [inProgress, setInProgress] = useState(false);

    const record = props.record;

    let title = 'Change a Delivery';
    if (!record.isRequested) {
        title += ` — Order No. ${record.orderNumber}`;
    }

    async function submit(values: DeliveryChange) {
        try {
            values.newDate = dateMoment(values.newDate).toDateString();
            values.deliveryRecordId = record.id;
            setInProgress(true);

            await api.postDeliveryChange(values);

            notification.sendDeliveryChangeSuccess();

            form.resetFields();
            props.close();
            
            props.refresh();

        } catch (error) {
            notification.sendDeliveryChangeError();
        }
        finally {
            setInProgress(false);
        }
    }

    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title={title} okText="Send Changes" confirmLoading={inProgress} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={submit}>
            <SiteSelector name="newSiteNumber" label="Site" initialValue={record.siteNumber} />

            <Row>
                <Col span={12}>
                    <FormItem label='New Delivery Date' name="newDate"
                        rules={[{ type: 'object', required: true, message: 'Please enter a date' }]}
                        initialValue={moment(record.date)}
                    >
                        <DatePicker format='DD-MMM-YYYY' disabledDate={(date: Moment) => date && date.valueOf() < Date.now()} showToday={false} placeholder='' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <TimeSlotSelector label='New Time Slot' name="newTimeSlot" initialValue={record.timeSlot} />
                </Col>
            </Row>

            <VolumeSelector label='New Volume' name="newQuantity" initialValue={record.quantity} step={1000} form={form} />

            <FormItem label='Notes' name="newNotes" initialValue={record.notes}>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
            </FormItem>
        </Form>
    </Modal>);
}