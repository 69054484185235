import { Col, Modal, Row } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { DeliveryRecord } from '../../../models/Deliveries';
import { notification } from '../../../notification';

interface Props {
    show: boolean;
    close(): void;
    record: DeliveryRecord;
    refresh(): void;
}

export default function (props: Props) {
    const [inProgress, setInProgress] = useState(false);

    const record = props.record;

    async function submit() {
        try {
            setInProgress(true);

            await api.postDeliveryDelete(record.id);

            notification.success('Delivery has been deleted');

            setInProgress(false);
            props.close();
            props.refresh();


        } catch (error) {
            notification.error('Delivery deletion has been failed');
            setInProgress(false);
        }
    }

    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title={`Delete a Delivery — Order No. ${props.record.orderNumber}`} okText="Yes, delete it!" okButtonProps={{danger: true}} confirmLoading={inProgress} onOk={submit}>

            <p>Are you really sure you want to delete the following Delivery Record? </p>

            <Row>
                <Col span={12}>
                    <strong>Due Date:</strong> {moment(record.date).format('Do MMMM YYYY')}<br />
                    <strong>Quantity:</strong> {Number(record.quantity).toLocaleString('en-GB')}<br />
                    {props.record.isRequested && <><strong>Reference Number:</strong> {record.id}</>}
                </Col>
                <Col span={12}>
                    <strong>Site Number:</strong> {record.siteNumber}<br />
                    <strong>Site:</strong> {record.siteName}<br />
                    <strong>Oil Company:</strong> {record.oilCompany}<br />
                </Col>
            </Row>
            <br />
            <p>The operation deletes any related entities (Queries, Confirmation, Changes) too.</p>

    </Modal>);
}