import { Input } from 'antd';
import React, { ChangeEvent, useState } from 'react';

interface Props {
    value: string | null;
    onChange(value: string): void;
    label: string;
}

export default function (props: Props) {

    const [state, setState] = useState({
        typeingTimeout: null as NodeJS.Timeout | null
    });

    function onChange(event: ChangeEvent<HTMLInputElement>){

        if(state.typeingTimeout){
            clearTimeout(state.typeingTimeout);
        }

        setState({
            typeingTimeout: setTimeout((args) => {
                props.onChange(args.value ?? '')
            }, 500, {value: event.target.value})
        });
    }
    
    return (<>
        <div>{props.label}:</div>
        <Input  autoComplete='off' allowClear onChange={onChange} defaultValue={props.value ?? ''} />
    </>);
}