import {  Modal } from 'antd';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { StockRecord } from '../../../models/Stock';
import { notification } from '../../../notification';
import StockSummary from './StockSummary';

interface Props {
    record: StockRecord;
    refresh(): void;
    show: boolean;
    close(): void;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    async function submit() {
        try {
            setInProgress(true);

            await api.postStockConfirmation(props.record.id);

            notification.sendStockConfirmationSuccess();

            props.close();
            props.refresh();

        } catch (error) {
            notification.sendStockConfirmationError ();
        }
        finally {
            setInProgress(false);
        }
    }

    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title="Confirm a Stock Record" okText="Confirm" confirmLoading={inProgress} onOk={submit}>
        <StockSummary record={props.record} />
    </Modal>);
}