import { Button } from 'antd';
import React from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { notifyError } from '../../../notification';
import { FontAwesome } from '../../FontAwesome';

interface Props {
    search: string | null;
    beforeDate?: string;
    afterDate?: string;
    status: string;
}

export default function (props: Props) {
    async function  download() {

        try {
            await api.downloadDeliveriesCsv(props.status, props.search, props.afterDate, props.beforeDate);
        } catch (error) {
            notifyError('Can\'t download the CSV file');
            // console.warn(error);
        }

    }

    return (<Button size="middle" onClick={download} >
            <FontAwesome name='download' /> Download as CSV</Button>);
}