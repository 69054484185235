import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { DeliveryRecord } from '../../../models/Deliveries';
import DeliveryDeleteModal from './DeliveryDeleteModal';

interface Props {
    record: DeliveryRecord;
    refresh(): void;
}

export default function (props: Props) {
    const [show, setShow] = useState(false);

    return (<>
        <Tooltip title='Delete record'>
            <Button shape='circle' type='primary' danger onClick={() => setShow(true)} style={{marginLeft: '5px'}} >
                <DeleteOutlined />
            </Button>
        </Tooltip>

        {show && <DeliveryDeleteModal show={show} close={() => setShow(false)} refresh={props.refresh} record={props.record} />}
    </>);
}