import { useAuth } from "oidc-react";

export interface UserInfo{
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    position: string | null;
    phone: string | null;
    email: string | null;
    isSuperAdmin: boolean;
    isAdmin: boolean;
    isManager: boolean;
    accessToken: string | null;
    id: string | null,
}

export function useUserInfo(): UserInfo{
    const auth = useAuth();

    let result: UserInfo = {
        isSuperAdmin: false,
        isAdmin: false,
        isManager: false,
        firstName: null,
        fullName: null,
        lastName: null,
        position: null,
        phone: null,
        email: null,
        accessToken: null,
        id: null,
    };

    if(auth && auth.userData){
        
        // Getting roles
        const roles = getRoles(auth.userData.profile);

        result.isSuperAdmin = roles.includes('SuperAdmin');
        result.isAdmin = roles.includes('Administrator');
        result.isManager = roles.includes('SiteGroupManager');

        
        // Getting basic info
        if(auth.userData.profile){
            
            const profile = auth.userData.profile;

            result.firstName = profile.given_name ?? null;
            result.lastName = profile.family_name ?? null;
            if(result.firstName || result.lastName){
                result.fullName = `${result.firstName} ${result.lastName}`.trim();
            }
            result.position = (auth.userData.profile as any)['siteportal.position'] ?? null;
            result.phone = profile.phone_number ?? null;
            result.email = profile.email ?? null;

            result.id = auth.userData.profile.sub;
        }

        // Acces token
        result.accessToken = auth.userData.access_token;
    }

    return result;
}

function getRoles(user: any): string[]{
    
    const result: string[] = [];

    // debugger;
    
    if(user.role){
        if(Array.isArray(user.role)){
            result.push(...(user.role as string[]));
        }
        else{
            result.push(user.role);
        }
    }

    return result;
}