import { Tooltip } from 'antd';
import React from 'react';
import { StockRecord } from '../../../models/Stock';
import { FontAwesome } from '../../FontAwesome';

interface Props {
    record: StockRecord;
}

export default function (props: Props) {
    return (<>
        { !props.record.isUnconfirmed && <Tooltip title='Confirmation sent' placement='right'>
            <span style={{ color: '#00a854' }}><FontAwesome name='check' /></span>
        </Tooltip>}
        {props.record.hasQuery && <Tooltip title='Query sent' placement='right'>
            <span><FontAwesome name='reply' /></span>
        </Tooltip>}
    </>);
}