import { Col, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { DeliveryQuery, DeliveryRecord } from '../../../models/Deliveries';
import { notification } from '../../../notification';

interface Props {
    record: DeliveryRecord;
    refresh(): void;
    show: boolean;
    close(): void;
}

export default function (props: Props) {

    const [form] = useForm();

    const [inProgress, setInProgress] = useState(false);

    const record = props.record;

    let title = 'Raise a Query';
    if (!record.isRequested) {
        title += ` — Order No. ${record.orderNumber}`;
    }

    async function submit(values: DeliveryQuery) {
        try {
            values.id = record.id;
            setInProgress(true);

            await api.postDeliveryQuery(values);

            notification.sendDeliveryQuerySuccess();

            form.resetFields();
            setInProgress(false);
            props.close();
            props.refresh();


        } catch (error) {
            notification.sendDeliveryQuerySuccess();
            setInProgress(false);
        }

    }

    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title={title} okText="Send Query" confirmLoading={inProgress} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={submit}>
            <Row>
                <Col span={12}>
                    <strong>Due Date:</strong> {moment(record.date).format('Do MMMM YYYY')}<br />
                    <strong>Quantity:</strong> {Number(record.quantity).toLocaleString('en-GB')}<br />
                </Col>
                <Col span={12}>
                    <strong>Site Number:</strong> {record.siteNumber}<br />
                    <strong>Site:</strong> {record.siteName}<br />
                    <strong>Oil Company:</strong> {record.oilCompany}<br />
                </Col>
            </Row>

            <FormItem label='Message' name="message" rules={[
                { required: true, message: 'Please enter a message' },
                { max: 500 }]}>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
            </FormItem>
        </Form>
    </Modal>);
}