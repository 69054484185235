import * as React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'antd';
import { useUserInfo } from '../backend/userInfoHook';


export default function () {

    const userInfo = useUserInfo();


    return (<>
        <Col span={6} className="footerMenu">
            <ul style={{ listStyle: 'disc', marginLeft: '1.3em' }}>
                <li><Link to={'/'}>Site Portal Home</Link></li>
                <li><Link to={'/sites'}>Manage Sites</Link></li>
                <li><Link to={'/deliveries'}>Manage Deliveries</Link></li>
                <li><Link to={'/stock'}>Manage Stock</Link></li>
                <li><Link to={'/transactions'}>View Transactions</Link></li>
                <li><Link to={'/documents'}>Manage Documents</Link></li>
                <li><Link to={'/users'}>Manage Users</Link></li>
                <li><Link to={'/contact'}>Contact UK Fuels</Link></li>
                <li><Link to={'/disclaimer'}>Disclaimer</Link></li>


            </ul>
        </Col>
        <Col span={6} className="footerMenu">

            {userInfo.isSuperAdmin && <ul><li>
                <Link to={'/reports'}>Reports</Link>

            </li>
                <li>
                    <Link to={'/splashmessages'}>Splash messages</Link>
                </li>
            </ul>
            }
        </Col>
    </>)


}