import React from 'react';
import { Col, Menu, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesome } from '../FontAwesome';
import ProfileDropDown from './Profile/ProfileDropDown';
import { useSelectorShallow } from '../../store/utils/useSelectorShallow';
import MenuWithCount from './MenuWithCount';

export default function () {

    const location = useLocation();

    // console.log(location.pathname);

    const data = useSelectorShallow(s => ({
        deliveryCount: s.status.deliveryCount,
        stockCount: s.status.stockCount
    }))

    return (<Row>
        <Col xl={4} md={4} sm={4}>
            <Link to={'/'}><img src='/images/site-portal-logo-115x48.png' width='115' height='48' alt='UK Fuels Site Portal' style={{ marginTop: '10px' }} /></Link>
        </Col>
        <Col xl={16} md={19} sm={19} className='nowrap' style={{ textAlign: 'center' }}>
            <Menu selectedKeys={[location.pathname]} className="mainMenu" mode="horizontal" style={{ display: 'inline-block' }} id="sp-main-menu" disabledOverflow>
                <Menu.Item key='/'>
                    <Link to={'/'}><FontAwesome name='home' size='lg' ariaLabel='Home' /></Link>
                </Menu.Item>
                <Menu.Item key='/sites'>
                    <Link to="/sites">Sites</Link>
                </Menu.Item>
                <Menu.Item key='/deliveries'>
                    <Link to={'/deliveries'}>
                        <MenuWithCount text="Deliveries" count={data.deliveryCount} />
                    </Link>
                </Menu.Item>
                <Menu.Item key='/stock'>
                    <Link to={'/stock'}>
                        <MenuWithCount text="Stock" count={data.stockCount} />
                    </Link>
                </Menu.Item>
                <Menu.Item key='/transactions'>
                    <Link to={'/transactions'}>Transactions</Link>
                </Menu.Item>
                <Menu.Item key='/documents'>
                    <Link to={'/documents'}>Documents</Link>
                </Menu.Item>
                <Menu.Item key='/users'>
                    <Link to={'/users'}>Users</Link>
                </Menu.Item>
                <Menu.Item key='/contact'>
                    <Link to={'/contact'}>Contact</Link>
                </Menu.Item>
            </Menu>
        </Col>
        <Col  xl={4} md={1} sm={1} style={{ textAlign: 'right' }}>
            <ProfileDropDown />
        </Col>
    </Row>
    );
}