import { ContactMessage } from "../components/Pages/Contact/ContactMessage";
import { DeliveryChange, DeliveryConfirmation, DeliveryQuery, DeliveryRequest } from "../models/Deliveries";
import { DocumentType } from "../models/Documents";
import { SiteRecord } from "../models/Sites";
import {  StockQuery } from "../models/Stock";
import { UserCreateData, UserEditData, UserPermissionData } from "../models/Users";
import { ApiCallerWithUserManager } from "./ApiCallerWithUserManager";
import { ApiFileDownloader } from "./ApiFileDownloader";
import appSettings from "./appSettings";

class SitePortalStaticApi{

    private caller = new ApiCallerWithUserManager(appSettings.backendUrl);

    private downloader = new ApiFileDownloader(appSettings.backendUrl);


    sendContactMessage(message: ContactMessage): Promise<Response>{
        return this.caller.postJson('/api/contact', message);
    }

    lookBusy = (f: () => void ) => setTimeout(f, 750);



    fetchSessionState2(): Promise<Response>{
        return this.caller.getJson('/api/status');
    }

    fetchDomains(){
        return this.caller.getJson('/api/domains');
    }

    postDomainChange(domainId: string | null){
        return this.caller.postJson(`/api/domain/set/${domainId}`, {});
    }

    downloadTransactionsCsv(search: string | null, product: string, beforeDate?: string, afterDate?: string){
        
        let queryParameters: any = {
            product: product,
            search: search,
            afterDate: afterDate,
            beforeDate: beforeDate
        };

        return this.downloader.download('/api/transactions/download', queryParameters);
    }

    fetchTransactions(page: number, pageSize: number, sortOrder: string, product: string, search: string, afterDate?: string, beforeDate?: string) {

        // console.log({afterDate, beforeDate});

        return this.caller.getJson('/api/transactions', {
            pageIndex: page,
            pageSize: pageSize,
            sortOrder: sortOrder,
            product: product,
            beforeDate: beforeDate,
            afterDate: afterDate,
            searchString: search,
        });
    };

    testEmail(){
        return this.caller.getJson('/api/deliveryemail');
    }

    fetchDeliveries(page: number, pageSize: number, sortOrder: string, status: string, search: string, afterDate?: string, beforeDate?: string){
        return this.caller.getJson('/api/deliveries', {
            pageIndex: page,
            pageSize: pageSize,
            sortOrder: sortOrder,
            status: status,
            beforeDate: beforeDate,
            afterDate: afterDate,
            searchString: search
        });
    }

    postDeliveryRequest(values: DeliveryRequest){
        return this.caller.postJson('/api/delivery/request', values);
    }

    fetchSitesWithStockBalances(){
        return this.caller.getJson('/api/sites/getwithstockbalances');
    }

    postDeliveryChange(values: DeliveryChange){
        return this.caller.postJson('/api/delivery/change', values);
    }

    postDeliveryQuery(values: DeliveryQuery){
        return this.caller.postJson('/api/delivery/query', values);
    }

    postDeliveryConfirmation(values: DeliveryConfirmation){
        return this.caller.postJson('/api/delivery/confirm', values);
    }

    downloadDeliveriesCsv(status: string, search: string | null, afterDate?: string, beforeDate?: string){
        return this.downloader.download('/api/delivery/download', {
            status: status,
            afterDate: afterDate,
            beforeDate: beforeDate,
            searchString: search
        });
    }

    postDeliveryDelete(id: string){
        return this.caller.postJson('/api/delivery/delete', {
            id: id
        })
    }

    fetchStocks(isoWeek: string){
        return this.caller.getJson('/api/stock', {
            isoWeek: isoWeek
        });
    }

    postStockQuery(values: StockQuery){
        return this.caller.postJson('/api/stock/query', values);
    }

    postStockConfirmation(id: number){
        return this.caller.postJson('/api/stock/confirm', {
            id: id
        });
    }

    downloadStockCsv(isoWeek: string){
        return this.downloader.download('/api/stock/download', {
            isoWeek: isoWeek
        });
    }

    fetchUsers(){
        return this.caller.getJson('/api/users');
    }

    postUserCreate(values: UserCreateData){
        return this.caller.postJson('/api/user/create', values);
    }

    postUserEdit(values: UserEditData){
        return this.caller.postJson('/api/user/update', values);
    }

    postUserDelete(id: string){
        return this.caller.postJson('/api/user/delete', {
            id: id
        });
    }

    postUserPermissions(values: UserPermissionData){
        return this.caller.postJson('/api/user/permissions', values);
    }

    fetchSites(){
        return this.caller.getJson('/api/sites');
    }

    postSiteUpdate(values: SiteRecord){
        return this.caller.postJson('/api/site/update', values);
    }

    fetchDocuments(){
        return this.caller.getJson('/api/documents');
    }

    downloadDocument(id: string, documentType: DocumentType){
        return this.downloader.download('/api/document/download', {
            id: id,
            type: documentType
        });
    }

    downloadReportUserDomain(){
        return this.downloader.download('/api/report/userdomains/download');
    }

    downloadReportUsageLog(type: string, startDate: string, endDate: string){
        return this.downloader.download('/api/report/usagelog', {
            type, startDate, endDate
        });

    }

    downloadReportNotificationConfirmation(emails: string, dateInterval: string[]){
        return this.downloader.download('/api/livenotification/confirmationreport', {
            emails: emails,
            dateInterval: dateInterval
        })
    }

    fetchSplashMessagesAdmin(page: number){
        return this.caller.getJson('/api/livenotification/getlist', { page: page});
    }

    postSplashMessageCreate(message: string){
        return this.caller.postJson('/api/livenotification/create', {
            messageText: message
        });
    }

    fetchSplashMessageNotifications(confirmed: number[], allShown: number[]){
        return this.caller.getJson('/api/livenotification/getnew',{
            confirmed: confirmed,
            allShown: allShown
        });
    }

    postSplashMessageNotificationConfirmation(id: number){
        return this.caller.postJson('/api/livenotification/confirm',{
            id: id
        });
    }
}

export const api = new SitePortalStaticApi();