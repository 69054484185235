import { Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { dateMoment } from '../../../backend/DateMoment';
import { api } from '../../../backend/SitePortalStaticApi';
import { DeliveryConfirmation, DeliveryRecord } from '../../../models/Deliveries';
import { notification } from '../../../notification';
import VolumeSelector from './VolumeSelector';

interface Props {
    record: DeliveryRecord;
    show: boolean;
    close(): void;
    refresh(): void;
}

export default function (props: Props) {

    const [form] = useForm();

    const [inProgress, setInProgress] = useState(false);

    async function submit(values: DeliveryConfirmation) {
        try {

            values.confirmedDate = dateMoment(values.confirmedDate).toDateString();
            values.id = props.record.id;
            setInProgress(true);

            await api.postDeliveryConfirmation(values);

            notification.sendDeliveryConfirmationSuccess();

            form.resetFields();
            props.close();
            
            props.refresh();

        } catch (error) {
            notification.sendDeliveryConfirmationError();
        }
        finally {
            setInProgress(false);
        }
    }

    const isDisabledDate = (d: Moment) => d && (d.valueOf() > Date.now() || d.isBefore(moment(props.record.date).subtract(1, 'days')));

    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title={`Confirm a Delivery — Order No. ${props.record.orderNumber}`} okText="Send Confirmation" confirmLoading={inProgress} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={submit}>
            <Row>
                <Col span={12}>
                    <FormItem label='Ticket Number' name="ticketNumber"
                        rules={[{ required: true, message: 'Please enter a ticket number' },
                            {max: 100}]}>
                        <Input style={{ width: '12em' }} autoComplete='off' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <strong>Site Number:</strong> {props.record.siteNumber}<br />
                    <strong>Site:</strong> {props.record.siteName}<br />
                    <strong>Oil Company:</strong> {props.record.oilCompany}
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <FormItem label='Delivery Date' name="confirmedDate"
                        rules={[{ type: 'object', required: true, message: 'Please enter a date' }]}
                        initialValue={moment(props.record.date)}
                    >
                        <DatePicker format='DD-MMM-YYYY' disabledDate={isDisabledDate} placeholder='' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <VolumeSelector label='Volume' name="confirmedQuantity" form={form} initialValue="" step={1} requiredValidationMessage='Please enter the exact quantity delivered as stated on your delivery note' />
                </Col>
                <Col span={24}>
                    <FormItem label='Notes' name="notes"
                        rules={[{ max: 500 }]}
                    >
                        <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </Modal>);
}