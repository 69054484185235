import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../backend/SitePortalStaticApi";
import { DocumentIndex, DocumentRecord, DocumentType, MonthWeekInfo } from "../../models/Documents";
import { notification } from "../../notification";
import { documentSliceLogic } from "../sliceLogics/DocumentSliceLogic";

export const documentSlice = createSlice({
    name: 'documents',
    initialState: {
        inProgress: false,
        data: [] as DocumentRecord[],
        filteredData: [] as DocumentRecord[],
        selectedYear: null as null | number,
        selectedMonthOrWeek: null as null | number,
        selectedType: null as null | DocumentType,
        selectedSite: null as null | string,


        fullIndex: {} as DocumentIndex,
        years: [] as number[],
        monthsOrWeeks: [] as MonthWeekInfo[],
        types: [] as DocumentType[],
        sites: [] as string[],
        

    },
    reducers: {
        startLoading(state) {
            state.inProgress = true;
        },
        endLoading(state) {
            state.inProgress = false;
        },
        setData(state, action: PayloadAction<DocumentRecord[]>) {
            state.data = action.payload;

            const fullIndex = documentSliceLogic.getFullIndex(action.payload);
            state.fullIndex = fullIndex;

            const typeStrings = Object.keys(fullIndex).sort((a, b) => a.localeCompare(b) * -1);

            // If there are documents to show
            if (typeStrings.length > 0) {

                // Types
                state.selectedType = Number(typeStrings[0]) as DocumentType;
                state.types = typeStrings.map(s => Number(s) as DocumentType);

                // SiteNumbers
                state.sites = Object.keys(fullIndex[state.selectedType]);
                state.selectedSite = state.sites[0];

                const yearStrings = Object.keys(fullIndex[state.selectedType][state.selectedSite]).sort((a, b) => a.localeCompare(b) * -1);
                state.selectedYear = Number(yearStrings[0]);
                state.years = yearStrings.map(s => Number(s));

                state.monthsOrWeeks = documentSliceLogic.getMonthOrWeeks(fullIndex[state.selectedType][state.selectedSite][state.selectedYear]);
                state.selectedMonthOrWeek = null;
            }

            // Do the filtering
            state.filteredData = documentSliceLogic.filter(action.payload, state.selectedYear, state.selectedMonthOrWeek, state.selectedSite, state.selectedType);

        },

        setType(state, action: PayloadAction<DocumentType>) {
            state.selectedType = action.payload;

            state.sites = Object.keys(state.fullIndex[state.selectedType]);

            // If the same site can be found in the selected type, then use it
            const earlierSite = state.selectedSite;

            if(!earlierSite || !state.sites.includes(earlierSite)){
                state.selectedSite = state.sites[0];
            }

            const yearStrings = Object.keys(state.fullIndex[state.selectedType][state.selectedSite ?? '']).sort((a, b) => a.localeCompare(b) * -1);
            state.selectedYear = Number(yearStrings[0]);
            state.years = yearStrings.map(s => Number(s));

            state.monthsOrWeeks = documentSliceLogic.getMonthOrWeeks(state.fullIndex[state.selectedType][state.selectedSite ?? ''][state.selectedYear]);
            state.selectedMonthOrWeek = null;


            state.filteredData = documentSliceLogic.filter(state.data, state.selectedYear, state.selectedMonthOrWeek, state.selectedSite, state.selectedType);
        },

        setSite(state, action: PayloadAction<string>) {
            state.selectedSite = action.payload;

            if (state.selectedType !== null) {

                const yearStrings = Object.keys(state.fullIndex[state.selectedType][state.selectedSite]).sort((a, b) => a.localeCompare(b) * -1);
                state.selectedYear = Number(yearStrings[0]);
                state.years = yearStrings.map(s => Number(s));

                state.monthsOrWeeks = documentSliceLogic.getMonthOrWeeks(state.fullIndex[state.selectedType][state.selectedSite][state.selectedYear]);
                state.selectedMonthOrWeek = null;


                state.filteredData = documentSliceLogic.filter(state.data, state.selectedYear, state.selectedMonthOrWeek, state.selectedSite, state.selectedType);
            }
        },

        setYear(state, action: PayloadAction<number>) {
            state.selectedYear = action.payload;

            state.monthsOrWeeks =  documentSliceLogic.getMonthOrWeeks(state.fullIndex[state.selectedType ?? -1][state.selectedSite ?? ''][state.selectedYear]);
            state.selectedMonthOrWeek = null;

            state.filteredData = documentSliceLogic.filter(state.data, state.selectedYear, state.selectedMonthOrWeek, state.selectedSite, state.selectedType);
        },

        setMonth(state, action: PayloadAction<number | null>) {
            state.selectedMonthOrWeek = action.payload

            state.filteredData = documentSliceLogic.filter(state.data, state.selectedYear, state.selectedMonthOrWeek, state.selectedSite, state.selectedType);
        },
    }
});



export function fetchDocumentsThunk() {
    return function (dispatch: Dispatch) {
        dispatch(documentSlice.actions.startLoading());

        return api.fetchDocuments()
            .then(response => response.json())
            .then((results: DocumentRecord[]) => {
                dispatch(documentSlice.actions.setData(results));
            })
            .catch(reason => notification.error('Unable to fetch Documents'))
            .finally(() => dispatch(documentSlice.actions.endLoading()));
    }
}