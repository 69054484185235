import { Button } from 'antd';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { notification } from '../../../notification';

export default function () {

    const [downloadInProgress, setDownloadInProgress] = useState(false);

    async function download() {
        if (!downloadInProgress) {
            try {
                setDownloadInProgress(true);
                await api.downloadReportUserDomain();

            } catch (error) {
                notification.error('Can\'t download the file');
            }
            finally {
                setDownloadInProgress(false);
            }
        }
    }

    return (<div>
        <h2>User Domains</h2>
        <p>
            You can download a .csv file that contains every user in the database and their domains.
        </p>
        <Button onClick={download}>Download as CSV</Button>
    </div>);
}