import { Card } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { DocumentIconInfo, DocumentRecord, DocumentType } from '../../../models/Documents';
import { notification } from '../../../notification';
import { FontAwesome } from '../../FontAwesome';

interface Props {
    record: DocumentRecord;
}

export default function (props: Props) {

    const record = props.record;

    const [downloadInProgress, setDownloadInProgress] = useState(false);

    const size = (record.fileSizeBytes / 1000).toLocaleString('en-gb', { maximumFractionDigits: 0 })

    const monthName = record.type === DocumentType.WeeklyStatement
        ? `Week ${record.month}`
        : moment.localeData().months()[record.month - 1];

    const date = `${monthName}, ${record.year}`;

    const iconInfo = getIconInfo(record.fileType, record.type);

    async function download() {
        if (!downloadInProgress) {
            try {
                setDownloadInProgress(true);
                await api.downloadDocument(record.id, record.type);
            } catch (error) {
                notification.error('Can\'t download the Document');
            }
            finally {
                setDownloadInProgress(false);
            }
        }
    }

    return (<div>
        <Card className='invoice' onClick={download}>
            Site Number {record.siteNumber}<br />
            {date}<br />
            <br />
            <div className='overlay'>
                <div className='swap'>
                    <div className='icon-stack'>
                        <FontAwesome name={iconInfo.icon} size='3x' style={{ fontSize: '5em', color: iconInfo.color }} />
                        <FontAwesome name='file-o' size='3x' style={{ fontSize: '5em' }} />
                    </div>
                </div>
                <div className='swap mouseover'>
                    <FontAwesome name='download' size='3x' style={{ fontSize: '5em' }} />
                </div>
            </div>
            <br />
            {iconInfo.name + record.fileType}<br />
            <span style={{ fontSize: '82.5%' }}>{size} KB</span>
        </Card>
    </div>);
}

function getIconInfo(fileExtension: string, documentType: DocumentType): DocumentIconInfo {
    switch (fileExtension) {
        case '.xlsx':
        case '.xls':
            return {
                icon: 'file-excel-o',
                name: documentType === DocumentType.SelfBill ? 'Self-Bill Details' : 'Statement Details',
                color: '#1F7244'
            };

        case '.pdf':
            return {
                icon: 'file-pdf-o',
                name: documentType === DocumentType.SelfBill ? 'Self-Bill Invoice' : 'Statement Details',
                color: '#f04134',
            };

        case '.csv':
            return {
                icon: 'file-text-o',
                name: documentType === DocumentType.SelfBill ? 'Self-Bill Details' : 'Statement Details',
                color: '#fc8803'
            };

        default:
            return {
                icon: 'file-o',
                name: 'File',
                color: '#88aa88'
            };
    }
}