import React from 'react';

interface Props {
    text?: string | null
}

export default function (props: Props) {

    if(!props.text){
        return (<></>);
    }

    const lines = props.text.split('\n');

    return (<>
        {lines.map((line, index) => <span key={index}>{line}<br  /></span>)} 
    </>);
}