import { Col, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { UserCreateData, UserType } from '../../../models/Users';
import { notification } from '../../../notification';

interface Props {
    refresh(): void;
    show: boolean;
    close(): void;
    title: string;
    action: UserType;
}

export default function (props: Props) {

    const [form] = useForm();

    const [inProgress, setInProgress] = useState(false);

    async function submit(values: UserCreateData) {

        try {
            setInProgress(true);
            values.action = props.action;

            await api.postUserCreate(values);

            notification.createUserSuccess();

            form.resetFields();
            props.close();
            props.refresh();


        } catch (error) {
            notification.createUserError();
        }
        finally {
            setInProgress(false);
        }

    }


    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title={props.title} okText="Create" confirmLoading={inProgress} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={submit}>
            <Row>
                <Col span={12}>
                    <FormItem label='First Name' style={{ marginRight: '1em' }}
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter a first name' }]} >
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label='Last Name'
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter a last name' }]}>
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <FormItem label='Position' style={{ marginRight: '1em' }} name="position">
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label='Company' name="company">
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <FormItem label='Email' style={{ marginRight: '1em' }} name="email"
                        rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}>
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label='Phone' name="phone">
                        <Input autoComplete='off' />
                    </FormItem>
                </Col>
            </Row>

        </Form>
    </Modal>);
}