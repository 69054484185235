import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { StockRecord } from '../../../models/Stock';
import StockConfirmationModalForm from './StockConfirmationModalForm';

interface Props {
    record: StockRecord;
    refresh(): void;
}

export default function (props: Props) {

    const [show, setShow] = useState(false);
    

    if (props.record.isUnconfirmed) {
        return (<>
            <Tooltip title='Confirm stock record'>
                <Button shape='circle' type='primary' icon={<CheckCircleOutlined />} onClick={() => setShow(true)} />
            </Tooltip>

            {show && <StockConfirmationModalForm show={show} close={() => setShow(false)} record={props.record} refresh={props.refresh} />}
        </>);

    }

    return (<Button shape='circle' type='primary' icon={<CheckCircleOutlined />} disabled />);
}