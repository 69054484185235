import { Tooltip } from 'antd';
import React from 'react';
import { DeliveryRecord } from '../../../../models/Deliveries';
import { FontAwesome } from '../../../FontAwesome';

interface Props {
    record: DeliveryRecord;
}

export default function (props: Props) {

    const record = props.record;

    if (!record.isActive) {
        switch (record['type']) {
            case 'Stock Credit':
                return (
                    <Tooltip title='Stock credit' placement='topLeft'>
                        <span /*style={{color: '#00a854'}}*/><FontAwesome name='plus'/></span>
                    </Tooltip>
                );
            case 'Stock Debit':
                return (
                    <Tooltip title='Stock debit' placement='topLeft'>
                        <span /*style={{color: '#00a854'}}*/><FontAwesome name='minus'/></span>
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip title='Delivered, confirmed' placement='topLeft'>
                        <span style={{color: '#00a854'}}><FontAwesome name='check'/></span>
                    </Tooltip>
                );
        }
    }
    // Requested
    if (record.isRequested) {
        // <span><FontAwesome name='share'/></span>
        return (
            <Tooltip title='Requested delivery' placement='topLeft'>
                <span style={{fontSize:'0.8em', position:'relative', top:'-0.05em' /*, left: '0.2em'*/ }}><FontAwesome name='circle-o'/></span>
            </Tooltip>
        );
    }
    // Future
    if (record.isFuture) {
        // yellow #ffbf00
        // for smaller dot use:-
        // 							<span style={{fontSize:'0.75em', position:'relative', top:'-0.05em', left:'0.2em', color: '#ffbf00'}}><FontAwesome name='circle'/></span>
        return (
            <Tooltip title='Delivery due' placement='topLeft'>
                <span style={{fontSize:'0.8em', position:'relative', top:'-0.05em', /*left: '0.2em',*/ color: '#ffbf00'}}><FontAwesome name='circle'/></span>
            </Tooltip>
        );
    }
    // Confirmed
    if (record.hasConfirmation) {
        // green #00a854
        return (
            <Tooltip title='Confirmation sent' placement='topLeft'>
                <span style={{fontSize:'1.15em', position:'relative', top:'-0.05em', /*left: '-0.1em',*/ color: '#00a854'}}><FontAwesome name='check-circle-o'/></span>
            </Tooltip>
        );
    }
    // Unconfirmed
    return (
        <Tooltip title='Unconfirmed delivery' placement='topLeft'>
            <span style={{color: '#ff3333'}}><FontAwesome name='exclamation-circle'/></span>
        </Tooltip>
    );
}