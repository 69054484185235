import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { documentSlice, fetchDocumentsThunk } from '../../../store/slices/DocumentSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import PageTitle from '../PageTitle';
import DocumentList from './DocumentList';
import DocumentListFilter from './DocumentListFilter';

export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.documents);

    useEffect(() => {

        if (reduxData.data.length === 0) {
            dispatch(fetchDocumentsThunk());
        }
        // eslint-disable-next-line
    }, []);

    return (<div>
        <PageTitle title="Documents" />
        <h1 style={{ textAlign: 'center' }}>Documents</h1>
        <br />
        <DocumentListFilter
            type={reduxData.selectedType}
            onTypeChange={(value) => dispatch(documentSlice.actions.setType(value))}
            types={reduxData.types}

            site={reduxData.selectedSite}
            sites={reduxData.sites}
            onSiteChange={value => dispatch(documentSlice.actions.setSite(value))}


            year={reduxData.selectedYear}
            years={reduxData.years}
            onYearChange={value => dispatch(documentSlice.actions.setYear(value))}

            monthOrWeek={reduxData.selectedMonthOrWeek}
            monthsOrWeeks={reduxData.monthsOrWeeks}
            onMonthOrWeekChange={value => dispatch(documentSlice.actions.setMonth(value))}
        />
        <DocumentList items={reduxData.filteredData} inProgress={reduxData.inProgress} />
    </div>);
}