import { Tooltip } from 'antd';
import React from 'react';
import { DeliveryRecord } from '../../../../models/Deliveries';
import { FontAwesome } from '../../../FontAwesome';

interface Props {
    record: DeliveryRecord;
}

export default function (props: Props) {

    const record = props.record;

    return (<div>
        {record.hasQuery && !record.hasConfirmation && <Tooltip title='Delivery query sent' placement='right' key='query'>
            <span><FontAwesome name='reply' /></span>
        </Tooltip>}

        {record.hasChanges && <Tooltip title='Delivery change request sent' placement='right' key='change'>
            <span><FontAwesome name='pencil-square-o' /></span>
        </Tooltip>}
    </div>);
}