import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../backend/SitePortalStaticApi";
import { PaginatedList } from "../../models/PaginatedList";
import { SplashMessageAdminListItem } from "../../models/SplashMessages";
import { notification } from "../../notification";
import { ApplicationState } from "../reducers";

export const splashMessageAdminSlice = createSlice({
    name: 'splashAdmin',
    initialState: {
        inProgress: false,
        data: {
            items: [],
            itemsPerPage: 20,
            pageIndex: 1,
            totalItems: 0,
            totalPages: 0
        } as PaginatedList<SplashMessageAdminListItem>,
        page: 1,
        pageSize: 20,
    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setData(state, action: PayloadAction<PaginatedList<SplashMessageAdminListItem>>) {
            state.data = action.payload
        },
        setPage(state, action: PayloadAction<number>) {
            state.page = action.payload
        }
    }
});

export function fetchSplashMessagesAdminThunk() {
    return function (dispatch: Dispatch, getState: () => ApplicationState) {
        dispatch(splashMessageAdminSlice.actions.startLoading());

        const state = getState().splashMessagesAdmin;

        return api.fetchSplashMessagesAdmin(state.page)
            .then(response => response.json())
            .then((results: PaginatedList<SplashMessageAdminListItem>) => {
                dispatch(splashMessageAdminSlice.actions.setData(results));
            })
            .catch(reason => notification.error('Can\'t load Splash Messages at the moment'))
            .finally(() => dispatch(splashMessageAdminSlice.actions.endLoading()));
    }

}