import React, { useEffect } from 'react';

interface Props {
    title: string;
    withoutPostfix?: boolean;
}

export default function (props: Props) {

    // eslint-disable-next-line
    useEffect(() => {
        if (props.withoutPostfix === true) {
            document.title = props.title;
        }
        else {
            document.title = `${props.title} - SitePortal`;
        }

        return () => {document.title = 'SitePortal - UK Fuels'};
    }, [props]);



    return (<></>);
}