import React from 'react';
import { Col, Row, Spin } from 'antd';
import './AuthLoadingIndicator.css';

export default function () {

    return (<>
        <div style={{ height: '100%' }}>
            <Row justify="center" align="middle" style={{ height: '20%' }}>
                <Col>
                    <img alt="UK Fuels" src="images/site-portal-logo-115x48.png" />
                </Col>
                {/* <Col>
                    
                    UK Fuels SitePortal

                </Col> */}
            </Row>

            <Row justify="center" align="middle" style={{ height: '40%' }}>
                <Col>

                    {/* Loading... */}
                    <Spin   />
                </Col>
            </Row>

        </div>
    </>);
}