import { Col, Row } from 'antd';
import React from 'react';
import { StockRecord } from '../../../models/Stock';
import StockNegativeNumber from './StockNegativeNumber';
import StockNumber from './StockNumber';

interface Props {
    record: StockRecord;
}

export default function (props: Props) {

    const record = props.record;

    return (<div>
        <Row>
            <Col span={12}>
                ISO Week: {record.isoWeek}<br />
                Site Number: {record.siteNumber}<br />
                Site: {record.siteName}<br />
            </Col>
            <Col span={12}>
                <table style={{width:'15em'}}>
                    <tbody>
                        <tr>
                            <td>Open Stock:</td>
                            <td style={{textAlign: 'right'}}> <StockNumber value={record.openStock} /></td>
                        </tr>
                        <tr>
                            <td>Delivered:</td>
                            <td style={{textAlign: 'right'}}><StockNumber value={record.delivered} /></td>
                        </tr>
                        <tr>
                            <td>Stock Credits:</td>
                            <td style={{textAlign: 'right'}}><StockNumber value={record.stockCredits} /></td>
                        </tr>
                        <tr>
                            <td>Drawings:</td>
                            <td style={{textAlign: 'right'}}><StockNegativeNumber value={record.drawings} /></td>
                        </tr>
                        <tr>
                            <td>Stock Debits:</td>
                            <td style={{textAlign: 'right'}}><StockNumber value={record.stockDebits} /></td>
                        </tr>
                        <tr>
                            <td>Current Stock:</td>
                            <td style={{textAlign: 'right'}}><StockNumber value={record.currentStock} /></td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
    </div>);
}