import moment from "moment";
import { DocumentIndex, DocumentRecord, DocumentType, MonthWeekInfo } from "../../models/Documents";

class DocumentSliceLogic {
    getFullIndex(data: DocumentRecord[]) {

        let fullIndex: DocumentIndex = {};

        for (let index = 0; index < data.length; index++) {
            const element = data[index];


            if (!fullIndex.hasOwnProperty(element.type)) {
                fullIndex[element.type] = {};
            }

            let byType = fullIndex[element.type];


            if (!byType.hasOwnProperty(element.siteNumber)) {
                byType[element.siteNumber] = {};
            }

            let bySite = byType[element.siteNumber];


            if (!bySite.hasOwnProperty(element.year)) {
                bySite[element.year] = {};
            }

            if(element.type === DocumentType.WeeklyStatement){
                bySite[element.year][element.month] = `Week ${element.month}`;
            } else{
                const monthName = moment.localeData().months()[element.month - 1];

                bySite[element.year][element.month] = monthName;
            }
        }

        return fullIndex;
    }


    filter(data: DocumentRecord[], year: null | number, monthOrWeek: null | number, site: null | string, type: null | DocumentType)
        : DocumentRecord[] {

        // I tried do some optimization by constructing the predicates outside the loop
        // I'm not sure it really faster :)

        const predicatesToApply = [] as ((item: DocumentRecord) => boolean)[];

        if (year) {
            predicatesToApply.push(s => s.year === year);
        }

        if (type !== null) {
            predicatesToApply.push(s => s.type === type);
        }

        if (monthOrWeek) {
            predicatesToApply.push(s => s.month === monthOrWeek);
        }

        if(site){
            predicatesToApply.push(s => s.siteNumber === site); 
        }



        return data.filter(item => {
            for (let index = 0; index < predicatesToApply.length; index++) {
                const predicate = predicatesToApply[index];

                if (!predicate(item)) {
                    return false;
                }
            }

            return true;
        });
    }

    getMonthOrWeeks(dictionary: Record<number, string>): MonthWeekInfo[]{

        const result: MonthWeekInfo[] = [];

        for (const key in dictionary) {
            result.push({
                number: Number(key),
                name: dictionary[key]
            })
        }

        result.sort((a, b) => b.number - a.number);

        return result;
    }
}

export const documentSliceLogic = new DocumentSliceLogic();