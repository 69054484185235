import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../backend/SitePortalStaticApi";
import { SiteWithStockBalance } from "../../models/SitesWithStockBalances";

export const siteWithStockBalanceSlice = createSlice({
    name: 'siteswithbalances',
    initialState: {
        data: [] as SiteWithStockBalance[],
        hasError: false
    },
    reducers: {
        setData(state, action: PayloadAction<SiteWithStockBalance[]>) { state.data = action.payload; state.hasError = false },
        setError(state){ state.hasError = true}
    }
});

export function fetchSiteWithStockBalancesThunk() {
    return function (dispatch: Dispatch) {
        return api.fetchSitesWithStockBalances()
            .then(response => response.json())
            .then(json => {
                dispatch(siteWithStockBalanceSlice.actions.setData(json as SiteWithStockBalance[]));
            })
            .catch(reason => {
                dispatch(siteWithStockBalanceSlice.actions.setError());
            })
    }
}