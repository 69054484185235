import * as React from 'react';

type Props = {
	// className?: string,
	// style?: React.CSSProperties,
	name: string,
	size?: 'lg' | '2x' | '3x' | '4x' | '5x',
	fixedWidth?: boolean,
	list?: 'ul' | 'li',
	border?: boolean,
	pull?: 'left' | 'right',
	animation?: 'spin' | 'pulse',
	rotate?: '90' | '180' | '270',
	flip?: 'horizontal' | 'vertical',
	inverse?: boolean,
	stack?: '1x' | '2x',
	ariaLabel?: string,
    style?: React.CSSProperties;
};

export function FontAwesome(props: Props) {
	// const { children, ...restProps } = props;
	const { name, size, fixedWidth, list, border, pull, animation, rotate, flip, inverse, stack, ariaLabel, style } = props;
	let classNames = [];
	classNames.push('fa');
	classNames.push('fa-' + name);
	if (size) {
		classNames.push('fa-' + size);
	}
	if (fixedWidth) {
		classNames.push('fa-fw');
	}
	if (list) {
		classNames.push('fa-' + list);
	}
	if (border) {
		classNames.push('fa-border');
	}
	if (pull) {
		classNames.push('fa-pull-' + pull);
	}
	if (animation) {
		classNames.push('fa-' + animation);
	}
	if (rotate) {
		classNames.push('fa-rotate-' + rotate);
	}
	if (flip) {
		classNames.push('fa-flip-' + flip);
	}
	if (inverse) {
		classNames.push('fa-inverse');
	}
	if (stack) {
		classNames.push('fa-stack-' + stack);
	}
	if (ariaLabel) {
		<i className={classNames.join(' ')} style={style} aria-label={ariaLabel} />
	}
	return <i className={classNames.join(' ')} style={style} aria-hidden='true' />
};

// export default FontAwesome;
