import {  Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { StockRecord } from '../../../models/Stock';
import { notification } from '../../../notification';
import StockSummary from './StockSummary';

interface Props {
    record: StockRecord;
    refresh(): void;
    show: boolean;
    close(): void;
}

export default function (props: Props) {

    const [form] = useForm();

    const [inProgress, setInProgress] = useState(false);

    async function submit(values: any) {
        try {
            values.id = props.record.id;
            setInProgress(true);

            await api.postStockQuery(values);

            notification.sendStockQuerySuccess();

            form.resetFields();
            props.close();
            props.refresh();

        } catch (error) {
            notification.sendStockQueryError();
        }
        finally {
            setInProgress(false);
        }
    }

    return (<Modal open={props.show} closable destroyOnClose onCancel={props.close}
        title="Query a Stock Record" okText="Send Query" confirmLoading={inProgress} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={submit}>
            
            <StockSummary record={props.record} />

            <FormItem label='Message' name="message" rules={[
                { required: true, message: 'Please enter a message' },
                { max: 500 }]}>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
            </FormItem>
        </Form>
    </Modal>);
}