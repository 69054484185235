import { Table, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import moment from "moment";
import { TransactionRecord, TransactionResults } from "../../../store/slices/TransactionSlice";
import { getTransactionsPagination } from "./TransactionsTablePagination";


const columns = [{
    // 	title: '',
    // 	key: 'status',
    // 	className: 'status-col',
    // }, {
    title: 'Site No.',
    dataIndex: 'siteNumber',
    key: 'siteNumber',
    sorter: true
}, {
    title: 'Site Name',
    dataIndex: 'siteName',
    key: 'siteName',
    sorter: true
}, {
    title: 'ISO No.',
    dataIndex: 'isoNumber',
    key: 'isoNumber',
    sorter: true
}, {
    title: 'Registration',
    dataIndex: 'registration',
    key: 'registration',
    sorter: true,
    render: (text: string) => <div className='nowrap'>{text}</div>
}, {
    // title: 'Transaction Timestamp',
    title: 'Transaction Date/Time',
    dataIndex: 'transactionDateTime',
    key: 'transactionDateTime',
    sorter: true,
    render: (text: string) => {
        return (
            <div className='nowrap'>
                <span style={{ width: '5.5em', display: 'inline-block' }}>{moment(text).format('DD-MMM-YY')}&nbsp;</span>
                <span>{moment(text).format('HH:mm')}</span>
            </div>
        );
    }
}, {
    title: 'Entry Date',
    dataIndex: 'entryDate',
    key: 'entryDate',
    sorter: true,
    render: (text: string) => <div className='nowrap'>{moment(text).format('DD-MMM-YY')}</div>
}, /*{
	title: 'Week No.',
	dataIndex: 'weekNumber',
	key: 'weekNumber',
}, */{
    title: 'ISO Week',
    dataIndex: 'isoWeek',
    key: 'isoWeek',
    sorter: true,
}, {
    title: 'TCode',
    dataIndex: 'tCode',
    key: 'tCode',
    sorter: true,
}, {
    title: 'Mileage',
    dataIndex: 'mileage',
    key: 'mileage',
    className: 'right-aligned-col',
    sorter: true,
    render: (text: string) => {
        var m = Number(text).toLocaleString('en-GB');
        if (m === 'NaN') {
            return text;
        }
        return m;
    }
}, {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    className: 'right-aligned-col',
    sorter: true,
    render: (text: string) => {
        var q = Number(text).toLocaleString('en-GB', { minimumFractionDigits: 2 });
        if (q === 'NaN') {
            return text;
        }
        return q;
    }
}];
interface Props {
    results: TransactionResults,
    pageSize: number,
    pageIndex: number,
    inProgress: boolean;
    onTableChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<TransactionRecord> | SorterResult<any>[]):void;

}

export default function (props: Props) {

    const results = props.results;

    const pagination = getTransactionsPagination(
        results.totalItems,
        props.pageIndex,
        props.pageSize,
        results.hasMoreThanLimit);

    return (<Table
        onChange={props.onTableChange}
        columns={columns}
        dataSource={results.items}
        bordered={true}
        rowKey="id"
        pagination={pagination}
        loading={{ delay: 300, size: 'large', spinning: props.inProgress }}
    />);
}