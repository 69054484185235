import { Tooltip } from 'antd';
import React from 'react';
import { DeliveryRecord } from '../../../../models/Deliveries';
import NegativeNumber from '../../../Utils/NegativeNumber';

interface Props {
    record: DeliveryRecord;
    text: string;
}

export default function (props: Props) {

    const record = props.record;

    const quantity = Number(props.text).toLocaleString('en-GB');
    if (record.hasConfirmation) {
        const confirmed = Number(record.confirmedQuantity).toLocaleString('en-GB');
        const title = (
            <table>
                <tbody>
                    <tr><td>Expected:</td><td style={{ textAlign: 'right', paddingLeft: '0.5em' }}>{quantity}</td></tr>
                    <tr><td>Confirmed as:</td><td style={{ textAlign: 'right', paddingLeft: '0.5em' }}>{confirmed}</td></tr>
                </tbody>
            </table>
        );
        return (
            <Tooltip title={title}>
                <span className='has-tooltip'>{confirmed}</span>
            </Tooltip>
        );
    }
    if (record['type'] === 'Stock Debit') {
        return <NegativeNumber value={Number(props.text)} />
    }
    return (<div>{quantity}</div>)
}