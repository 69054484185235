import { Card, Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { fetchStocksThunk, stockSlice } from '../../../store/slices/StockSlice';
import { useSelectorShallow } from '../../../store/utils/useSelectorShallow';
import PageTitle from '../PageTitle';
import StockTable from './StockTable';
import StockTableFilter from './StockTableFilter';

export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.stocks);

    function refreshPage() {
        dispatch(fetchStocksThunk());
    }

    useEffect(() => {
        refreshPage();
        // eslint-disable-next-line
    }, []);


    return (<div>
        <PageTitle title="Stocks" />
        <h1 style={{ textAlign: 'center' }}>Manage Stock</h1>
        <br />
        <Row>
            <Col span={18}>
                <div>
                    <StockTableFilter
                        isoWeek={reduxData.data.isoWeek}
                        isoWeekList={reduxData.data.isoWeeks}
                        onIsoWeekChange={value => {
                            dispatch(stockSlice.actions.setIsoWeek(value));
                            dispatch(fetchStocksThunk())
                        }} />
                    <StockTable refresh={refreshPage} items={reduxData.data.stockRecords} inProgress={reduxData.inProgress} />
                </div>
            </Col>
            <Col span={6}>
                <Card style={{ marginLeft: '1em', marginBottom: '16px' }}>
                    <b>Please note:</b> The drawings column includes all drawings dispensed up to midnight Sunday.
                </Card>
                <Card title="Stock balance confirmation" style={{ marginLeft: '1em' }}>
                    <p>We would be grateful if you would confirm your site stock balance each week, or alternatively send any stock query to us, via the blue Action buttons.</p>
                </Card>
            </Col>
        </Row>
    </div>);
}