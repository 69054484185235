import {  DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import { api } from '../../../backend/SitePortalStaticApi';
import { User } from '../../../models/Users';
import { notification } from '../../../notification';

interface Props {
    refresh(): void;
    record: User;
    text: string;
    disabled: boolean;
}

export default function (props: Props) {

    const [show, setShow] = useState(false);

    const [inProgress, setInProgress] = useState(false);



    if (props.disabled) {
        return (<Button shape='circle' type='primary' danger  disabled style={{ marginRight: '5px' }}>
            <DeleteOutlined />
        </Button>);
    }

    async function submit() {
        try {
            setInProgress(true);

            await api.postUserDelete(props.record.id);

            notification.deleteUserSuccess();

            setInProgress(false);
            props.refresh();
            setShow(false);

        } catch (error) {
            notification.updateUserProfileError();
            setInProgress(false);
        }
    }

    return (<>
        <Tooltip title={props.text}>
            <Button shape='circle' type="primary" danger onClick={() => setShow(true)} style={{ marginRight: '5px' }}>
                <DeleteOutlined />
            </Button>
        </Tooltip>

        {show && <Modal open={show} closable destroyOnClose onCancel={() => setShow(false)}
            title={props.text} okText="Yes, delete!" confirmLoading={inProgress} onOk={submit} >
            <p>
                Are you sure you want to delete user <strong>{props.record.firstName} {props.record.lastName}</strong>?
                </p>
        </Modal>}
    </>);
}